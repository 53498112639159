import * as React from 'react'
import { DateChecksBlock, DateLabel, DateCheck, DateChecksItem } from './DateChecks.styles'
import { CheckboxesProps, DateChecksProps, DateType } from './DateChecks.types'
import { useDateCheck } from './hooks/useDateCheck'
import { RegularDayOfWeekType } from 'types/RegisterOwner'

const Checkboxes: React.FC<CheckboxesProps> = ({
  checkboxes,
  setCheckbox,
  handleChangeRegularBusinessDayOfWeek,
}) => {
  React.useEffect(() => {
    const regularBusinessDay = checkboxes.reduce((acc: DateType | {}, val: DateType) => {
      acc = { ...acc, [val.label]: val.checked }
      return acc
    }, {})

    handleChangeRegularBusinessDayOfWeek(regularBusinessDay as unknown as RegularDayOfWeekType)
  }, [checkboxes])

  return (
    <DateChecksBlock>
      {checkboxes.map((checkbox: any, index: number) => (
        <DateChecksItem key={index}>
          <DateCheck
            id={checkbox.label}
            type="checkbox"
            checked={checkbox.checked}
            onChange={(e) => setCheckbox(index, e.target.checked)}
          />
          <DateLabel htmlFor={checkbox.label}>{checkbox.text}</DateLabel>
        </DateChecksItem>
      ))}
    </DateChecksBlock>
  )
}

export const DateChecks: React.FC<DateChecksProps> = ({ handleChangeRegularBusinessDayOfWeek }) => {
  const checkboxes = useDateCheck()
  return (
    <Checkboxes
      {...checkboxes}
      handleChangeRegularBusinessDayOfWeek={handleChangeRegularBusinessDayOfWeek}
    />
  )
}
