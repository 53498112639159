import Select, { ExportSelectHandlers } from 'components/common/Select'
import {
  BusinessTimeFormBusinessTypeDaySelectWrapper,
  BusinessTimeFormBusinessTypeTabItem,
  BusinessTimeFormBusinessTypeTabWrapper,
  BusinessTimeFormSelectDaysTitle,
  BusinessTimeFormSelectInfoTitle,
  BusinessTimeFormWrapper,
} from './BusinessTimeForm.styles'
import { Flex } from 'components/common'
import { useRef, useState } from 'react'
import { getTimesByTimeUnit, TimeType } from 'utils/common/times'
import Spacing from 'components/common/Spacing'
import dayjs from 'dayjs'
import { SelectValue } from 'antd/lib/select'
import {
  BUSINESS_DAYS_OF_WEEK_KOREAN_MAP,
  DEFAULT_BUSINESS_DAYS_OF_WEEK_TIME_UNIT,
  INITIAL_BUSINESS_DAYS_OF_WEEK,
  INITIAL_BUSINESS_DAYS_OF_WEEK_TAB_TYPE,
  INITIAL_BUSNESS_DAYS_OF_WEEK_TIME,
} from 'constants/RegisterOwner'
import { BusinessDaysOfWeek, BusinessDaysOfWeekItemType } from 'types/RegisterOwner'

type SelectedBusinessType = 'business-type-all' | 'business-type-day'

type BusinessTimeFormProps = {
  activatedDaysOfWeek?: BusinessDaysOfWeek[]
  businessDaysOfWeek: BusinessDaysOfWeekItemType[]
  handleChangeBusinessDaysOfWeek: (businessDaysOfWeek: BusinessDaysOfWeekItemType[]) => void
}
const BusinessTimeForm = ({
  activatedDaysOfWeek,
  handleChangeBusinessDaysOfWeek,
}: BusinessTimeFormProps) => {
  const [selectedBusinessType, setSelectedBusinessType] = useState<SelectedBusinessType>(
    INITIAL_BUSINESS_DAYS_OF_WEEK_TAB_TYPE,
  )

  const [businessTypeAllBusinessDaysOfWeek, setBusinessTypeAllBusinessDaysOfWeek] = useState(
    INITIAL_BUSINESS_DAYS_OF_WEEK,
  )
  const [businessTypeDayBusinessDaysOfWeek, setBusinessTypeDayBusinessDaysOfWeek] = useState(
    INITIAL_BUSINESS_DAYS_OF_WEEK,
  )

  const handleChangeBusinessTypeTab = (kind: SelectedBusinessType) => {
    // TODO: 탭 변경시 기본값 변경
    const updatedBusinessDaysOfWeek =
      kind === 'business-type-all'
        ? businessTypeAllBusinessDaysOfWeek
        : businessTypeDayBusinessDaysOfWeek
    handleChangeBusinessDaysOfWeek(updatedBusinessDaysOfWeek)
    setSelectedBusinessType(kind)
  }

  return (
    <BusinessTimeFormWrapper>
      <BusinessTimeFormBusinessTypeTabWrapper>
        {(
          [
            {
              name: '모든 영업일 동일 지정',
              kind: 'business-type-all',
            },
            {
              name: '요일별 별도 지정',
              kind: 'business-type-day',
            },
          ] as const
        ).map(({ name, kind }) => (
          <BusinessTimeFormBusinessTypeTabItem
            as={'button'}
            className={`${selectedBusinessType === kind ? 'active' : ''}`}
            onClick={() => handleChangeBusinessTypeTab(kind)}
            key={kind}
            type="button"
          >
            {name}
          </BusinessTimeFormBusinessTypeTabItem>
        ))}
      </BusinessTimeFormBusinessTypeTabWrapper>
      {
        {
          'business-type-all': (
            <BusinessTypeAllSelect
              businessDaysOfWeek={businessTypeAllBusinessDaysOfWeek}
              handleChangeBusinessDaysOfWeek={(businessDaysOfWeek) => {
                handleChangeBusinessDaysOfWeek(businessDaysOfWeek)
                setBusinessTypeAllBusinessDaysOfWeek(businessDaysOfWeek)
              }}
            />
          ),
          'business-type-day': (
            <BusinessTypeDaySelect
              activatedDaysOfWeek={activatedDaysOfWeek}
              businessDaysOfWeek={businessTypeDayBusinessDaysOfWeek}
              handleChangeBusinessDaysOfWeek={(businessDaysOfWeek) => {
                handleChangeBusinessDaysOfWeek(businessDaysOfWeek)
                setBusinessTypeDayBusinessDaysOfWeek(businessDaysOfWeek)
              }}
            />
          ),
        }[selectedBusinessType]
      }
    </BusinessTimeFormWrapper>
  )
}

export default BusinessTimeForm

type BusinessTypeAllSelectProps = BusinessTimeFormProps
const BusinessTypeAllSelect = ({
  businessDaysOfWeek,
  handleChangeBusinessDaysOfWeek,
}: BusinessTypeAllSelectProps) => {
  const { timeOptions, startTimeOptions, endTimeOptions } = getTimeSelectOptions()

  const [timeRangeState, setTimeRangeState] = useState({
    startTime: businessDaysOfWeek[0]?.startTime || INITIAL_BUSNESS_DAYS_OF_WEEK_TIME.startTime,
    endTime: businessDaysOfWeek[0]?.endTime || INITIAL_BUSNESS_DAYS_OF_WEEK_TIME.endTime,
  })
  const startTimeSelectRef = useRef<ExportSelectHandlers>(null)
  const endTimeSelectRef = useRef<ExportSelectHandlers>(null)

  const updateBusinessDaysOfWeek = ({
    startTime,
    endTime,
  }: {
    startTime: string
    endTime: string
  }) => {
    const updatedBusinessDaysOfWeek = businessDaysOfWeek.map((businessDay) => {
      return {
        ...businessDay,
        startTime,
        endTime,
      }
    })
    setTimeRangeState({
      startTime,
      endTime,
    })
    handleChangeBusinessDaysOfWeek(updatedBusinessDaysOfWeek)
  }

  const handleChangeStartTime = (value: SelectValue) => {
    const startTime = value as string
    let endTime = timeRangeState.endTime as string
    if (timeOptions.length > 0) {
      const lastTime = timeOptions[timeOptions.length - 1].time
      if (endTime !== lastTime && startTime >= endTime) {
        const addedEndTime = addTime(startTime, DEFAULT_BUSINESS_DAYS_OF_WEEK_TIME_UNIT)
        if (endTimeSelectRef.current) {
          endTimeSelectRef.current.changeValue(addedEndTime)
        }
        endTime = addedEndTime
      }
    }
    updateBusinessDaysOfWeek({
      startTime,
      endTime,
    })
  }

  const handleChangeEndTime = (value: SelectValue) => {
    const endTime = value as string
    let startTime = timeRangeState.startTime as string
    if (timeOptions.length > 0) {
      const firstTime = timeOptions[0].time
      if (startTime !== firstTime && endTime <= startTime) {
        const subtractedStartTime = addTime(endTime, DEFAULT_BUSINESS_DAYS_OF_WEEK_TIME_UNIT * -1)
        if (startTimeSelectRef.current) {
          startTimeSelectRef.current.changeValue(subtractedStartTime)
        }
        startTime = subtractedStartTime
      }
    }
    updateBusinessDaysOfWeek({
      startTime,
      endTime,
    })
  }
  return (
    <>
      <Flex gap={8}>
        <BusinessTimeFormSelectInfoTitle>시작</BusinessTimeFormSelectInfoTitle>
        <BusinessTimeFormSelectInfoTitle>종료</BusinessTimeFormSelectInfoTitle>
      </Flex>
      <Spacing size="4" />
      <Flex gap={8}>
        <Select
          ref={startTimeSelectRef}
          onChangeValue={(name, value) => handleChangeStartTime(value)}
          defaultValue={timeRangeState.startTime}
          name="startBusinessTime"
          options={startTimeOptions}
        />
        <Select
          ref={endTimeSelectRef}
          onChangeValue={(name, value) => handleChangeEndTime(value)}
          defaultValue={timeRangeState.endTime}
          name="endBusinessTime"
          options={endTimeOptions}
        />
      </Flex>
    </>
  )
}

type BusinessTypeDaySelectProps = BusinessTimeFormProps
const BusinessTypeDaySelect = ({
  activatedDaysOfWeek,
  businessDaysOfWeek,
  handleChangeBusinessDaysOfWeek,
}: BusinessTypeDaySelectProps) => {
  const { timeOptions, startTimeOptions, endTimeOptions } = getTimeSelectOptions()

  const updateBusinessDaysOfWeek = ({
    dayOfWeek,
    startTime,
    endTime,
  }: BusinessDaysOfWeekItemType) => {
    const updatedBusinessDaysOfWeek = businessDaysOfWeek.map((businessDay) => {
      if (businessDay.dayOfWeek === dayOfWeek) {
        return {
          ...businessDay,
          startTime,
          endTime,
        }
      }
      return businessDay
    })
    handleChangeBusinessDaysOfWeek(updatedBusinessDaysOfWeek)
  }

  const handleChangeStartTime = (dayOfWeek: BusinessDaysOfWeek, value: SelectValue) => {
    const startTime = value as string
    let endTime = businessDaysOfWeek.find((item) => item.dayOfWeek === dayOfWeek)?.endTime as string
    if (timeOptions.length > 0) {
      const lastTime = timeOptions[timeOptions.length - 1].time
      if (endTime !== lastTime && startTime >= endTime) {
        const addedEndTime = addTime(startTime, DEFAULT_BUSINESS_DAYS_OF_WEEK_TIME_UNIT)
        endTime = addedEndTime
      }
    }
    updateBusinessDaysOfWeek({
      dayOfWeek,
      startTime,
      endTime,
    })
  }

  const handleChangeEndTime = (dayOfWeek: BusinessDaysOfWeek, value: SelectValue) => {
    const endTime = value as string
    let startTime = businessDaysOfWeek.find((item) => item.dayOfWeek === dayOfWeek)
      ?.startTime as string
    if (timeOptions.length > 0) {
      const firstTime = timeOptions[0].time
      if (startTime !== firstTime && endTime <= startTime) {
        const subtractedStartTime = addTime(endTime, DEFAULT_BUSINESS_DAYS_OF_WEEK_TIME_UNIT * -1)
        startTime = subtractedStartTime
      }
    }
    updateBusinessDaysOfWeek({
      dayOfWeek,
      startTime,
      endTime,
    })
  }

  return (
    <>
      <Flex width={'100%'} gap={16}>
        <BusinessTimeFormSelectDaysTitle />
        <Flex width={'100%'} gap={8}>
          <BusinessTimeFormSelectInfoTitle>시작</BusinessTimeFormSelectInfoTitle>
          <BusinessTimeFormSelectInfoTitle>종료</BusinessTimeFormSelectInfoTitle>
        </Flex>
      </Flex>
      <Flex flexDirection="column" gap={12}>
        {businessDaysOfWeek.map(({ dayOfWeek, startTime, endTime }) => {
          const isActivated = activatedDaysOfWeek?.includes(dayOfWeek as BusinessDaysOfWeek)
          return (
            <BusinessTimeFormBusinessTypeDaySelectWrapper
              className={!isActivated ? 'disabled' : ''}
              key={dayOfWeek}
            >
              <BusinessTimeFormSelectDaysTitle>
                {BUSINESS_DAYS_OF_WEEK_KOREAN_MAP[dayOfWeek]}요일
              </BusinessTimeFormSelectDaysTitle>
              <Flex gap={8} width={'100%'}>
                <Select
                  disabled={!isActivated}
                  value={startTime}
                  onChangeValue={(name, value) => handleChangeStartTime(dayOfWeek, value)}
                  defaultValue={startTime}
                  name="startBusinessTime"
                  options={startTimeOptions}
                />
                <Select
                  disabled={!isActivated}
                  value={endTime}
                  onChangeValue={(name, value) => handleChangeEndTime(dayOfWeek, value)}
                  defaultValue={endTime}
                  name="endBusinessTime"
                  options={endTimeOptions}
                />
              </Flex>
            </BusinessTimeFormBusinessTypeDaySelectWrapper>
          )
        })}
      </Flex>
    </>
  )
}

const addTime = (time: string, timeUnit: number) => {
  const timeRemoveBullet = time.replace(':', '')
  const hour = parseInt(timeRemoveBullet.substring(0, 2), 10)
  const minute = parseInt(timeRemoveBullet.substring(2, 4), 10)
  const addedDate = dayjs().set('hour', hour).set('minute', minute).add(timeUnit, 'minute')

  return hour >= 23 && addedDate.hour() === 0 ? '24:00' : addedDate.format('HH:mm')
}

const buildTimeSelectOption = (timeOptions: TimeType[]) => {
  return timeOptions.map(({ time, display }) => ({
    name: display,
    value: time,
  }))
}

const getTimeSelectOptions = () => {
  const timeOptions = getTimesByTimeUnit(DEFAULT_BUSINESS_DAYS_OF_WEEK_TIME_UNIT)

  const startTimeOptions = buildTimeSelectOption(timeOptions.slice(0, timeOptions.length - 1))
  const endTimeOptions = buildTimeSelectOption(timeOptions.slice(1))

  return {
    timeOptions,
    startTimeOptions,
    endTimeOptions,
  }
}
