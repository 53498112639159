import { ReactNode } from 'react'
import { ContentWrapper, ContentWrapperProps } from './Content.styles'

type ContentProps = {
  children: ReactNode
} & Partial<ContentWrapperProps>

const Content = ({ children, fullHeight = false }: ContentProps) => {
  return <ContentWrapper fullHeight={fullHeight}>{children}</ContentWrapper>
}

export default Content
