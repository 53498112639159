import * as React from 'react'
import qs from 'qs'
import { useLocation, useNavigate } from 'react-router-dom'
import { authAtom } from 'stores/atoms'
import { useSetRecoilState } from 'recoil'
import { queryPramsAtom } from 'stores/atoms/users'
import { parseJwt } from 'lib/helper'
import { getUserByEmpNo } from 'services/user.service'
import FullContent from 'template/FullContent'
import {
  AuthRegisterRolesContentBox,
  AuthRegisterRolesContentFooter,
  AuthRegisterRolesContentWrapper,
  AuthRegisterRolesEmployeeIcon,
  AuthRegisterRolesRoleItem,
  AuthRegisterRolesRoleItemTitle,
  AuthRegisterRolesRoleWrapper,
  AuthRegisterRolesStoreIcon,
  AuthRegisterRolesSubTitle,
  AuthRegisterRolesTitle,
  AuthRegisterRolesTitleIconWrapper,
  AuthRegisterRolesTitleWrapper,
} from './AuthRegisterRoles.styles'
import Icon from 'components/common/Icon'
import Spacing from 'components/common/Spacing'
import { SubTitle, Text } from 'components/common'
import Breakpoint from 'components/common/Breakpoint'

export const RegisterRolesPage: React.FC = () => {
  const setAuthState = useSetRecoilState(authAtom)
  const setQueryParams = useSetRecoilState(queryPramsAtom)

  const navigate = useNavigate()
  const location = useLocation()

  const qsData = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  React.useEffect(() => {
    const { returnUrl } = qsData
    if (Object.keys(qsData).length) {
      setQueryParams({
        returnUrl: returnUrl as string,
      })
    }
  }, [qsData, setQueryParams])

  React.useEffect(() => {
    ;(async () => {
      try {
        const { token } = qsData

        if (Object.keys(qsData).length) {
          const empNo = parseJwt(token as string)

          const {
            data: { data, errorResponse },
          } = await getUserByEmpNo(empNo.empno)

          if (errorResponse.status !== 200) {
            return
          }

          setAuthState(data)
        }
      } catch (error) {
        console.error(error)
      }
    })()
  }, [qsData, setAuthState])

  return (
    <FullContent>
      <Breakpoint.Up size="md">
        <FullContent.Header>
          <FullContent.HeaderTitle icon="reserveStateCustomer" title="회원가입" />
        </FullContent.Header>
      </Breakpoint.Up>
      <FullContent.Content>
        <AuthRegisterRolesContentWrapper>
          <AuthRegisterRolesContentBox>
            <AuthRegisterRolesTitleWrapper>
              <AuthRegisterRolesTitleIconWrapper>
                <Icon icon="crCheck" size={25} />
              </AuthRegisterRolesTitleIconWrapper>
              <Spacing size="20" />
              <AuthRegisterRolesTitle>회원가입이 완료되었습니다.</AuthRegisterRolesTitle>
              <Spacing size="8" />
              <AuthRegisterRolesSubTitle className="color-gray-500">
                우리샵 등록(원장님) 또는 입사 신청(직원)하고,{' '}
                <span className="highlight">공비서를 무료</span>로 이용해 보세요.
              </AuthRegisterRolesSubTitle>
            </AuthRegisterRolesTitleWrapper>
            <AuthRegisterRolesRoleWrapper>
              {[
                {
                  icon: <AuthRegisterRolesStoreIcon />,
                  subTitle: '원장님이신가요?',
                  title: '샵 등록하기',
                  path: '/register/owner',
                },
                {
                  icon: <AuthRegisterRolesEmployeeIcon />,
                  subTitle: '직원이신가요?',
                  title: '입사 신청하기',
                  path: '/register/employee',
                },
              ].map(({ icon, subTitle, title, path }) => {
                return (
                  <AuthRegisterRolesRoleItem onClick={() => navigate(path)}>
                    {icon}
                    <div>
                      <SubTitle.M20 className="color-gray-400">{subTitle}</SubTitle.M20>
                      <AuthRegisterRolesRoleItemTitle>{title}</AuthRegisterRolesRoleItemTitle>
                    </div>
                  </AuthRegisterRolesRoleItem>
                )
              })}
            </AuthRegisterRolesRoleWrapper>
            <AuthRegisterRolesContentFooter>
              <Text.M20
                className="color-gray-300"
                as={'a'}
                href={`${process.env.REACT_APP_HOST_URL}/signin`}
              >
                로그아웃
              </Text.M20>
            </AuthRegisterRolesContentFooter>
          </AuthRegisterRolesContentBox>
        </AuthRegisterRolesContentWrapper>
      </FullContent.Content>
    </FullContent>
  )
}
