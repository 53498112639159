import styled from 'styled-components'

import { Icon } from '@chakra-ui/react'

import { CategoryType } from 'components/CategoriesSelects/Category/Category.types'

import media from 'lib/styles/media'
import palette from 'lib/styles/palette'
import { css } from 'styled-components'
import { SubTitle } from 'components/common'

interface CategoryItemsProps {
  fetchedCategories: CategoryType[]
  selectedCategories: string[]
  onChangeChecked: (category: CategoryType) => void
}

export const CategoryItems: React.FC<CategoryItemsProps> = ({
  fetchedCategories,
  selectedCategories,
  onChangeChecked,
}) => {
  return (
    <CategoryItemsBlock>
      {fetchedCategories.map((category: CategoryType, idx: number) => {
        const isChecked = selectedCategories.includes(category.name)
        return (
          <div key={idx} style={{ width: '100%', position: 'relative' }}>
            <CategoryCheckBox
              id={category.value}
              type="checkbox"
              checked={isChecked}
              onChange={(e) => {
                onChangeChecked(category)
              }}
            />
            <CategoryCheckboxLabel htmlFor={category.value}>
              <Icon as={category.icon} />
              <SubTitle.S>{category.value}</SubTitle.S>
            </CategoryCheckboxLabel>
            {isChecked && (
              <CategoryCheckboxBadge>
                {selectedCategories.findIndex((name) => category.name === name) + 1}
              </CategoryCheckboxBadge>
            )}
          </div>
        )
      })}
    </CategoryItemsBlock>
  )
}

const CategoryItemsBlock = styled.div`
  padding: 40px 0;
  display: grid;
  justify-items: center;
  grid-auto-flow: row;
  grid-template-columns: repeat(4, 1fr);

  margin: 0 auto;
  gap: 8px;

  ${({ theme }) => css`
    ${theme.breakpoint.mediaQuery.down('md')} {
      padding: 40px 0 48px 0;
      grid-template-columns: repeat(3, 1fr);
      gap: 4px;
    }
  `}
`

const CategoryCheckBox = styled.input`
  ${({ theme }) => css`
    display: none;

    &:checked ~ label {
      filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.2));
      background-color: ${theme.color.primary[300]};
      border-color: ${theme.color.primary[300]};
      color: ${theme.color.gray[0]};
      > svg {
        path {
          fill: ${theme.color.gray[0]};
        }
      }
    }
    &:not(:checked):hover ~ label {
      border-color: ${theme.color.gray[200]};
    }
  `}
`

const CategoryCheckboxLabel = styled.label`
  ${({ theme }) => css`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    border: 1px solid ${theme.color.gray[100]};
    color: ${theme.color.gray[700]};
    padding: 12px 0 13px 0;
    width: 100%;
    border-radius: 0.5rem;
    > h5 {
      color: inherit;
    }
    > svg {
      width: 24px;
      height: 24px;
      path {
        fill: ${theme.color.gray[700]};
      }
    }
  `}
`

const CategoryCheckboxBadge = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
  justify-content: center;
  color: ${palette.main_blue};
  font-size: 14px;
`
