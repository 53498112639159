import styled, { css } from 'styled-components'
import { Title } from '..'

export const MobileTopbarWrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  height: 44px;
  padding: 0 16px;
  gap: 12px;
  ${({ theme }) => css`
    background: ${theme.color.bg[100]};
  `}
`

type MobileTopbarColumnProps = {
  column: 'left' | 'center' | 'right'
}
export const MobileTopbarColumn = styled.div<MobileTopbarColumnProps>`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ column }) => {
    switch (column) {
      case 'left':
        return css`
          justify-content: flex-start;
        `
      case 'center':
        return css`
          justify-content: center;
          overflow: hidden;
          max-width: 200px;
        `
      case 'right':
        return css`
          justify-content: flex-end;
        `
    }
  }}
`

export const MobileTopbarTitle = styled(Title.L)``

export const MobileTopbarIconButtonWrapper = styled.button`
  flex-shrink: 0;
  font-size: 0;
`
