import { useState } from 'react'
import { nameRegex } from 'lib/helper'

type UserName = {
  value: string
  status: 'SUCCESS01' | 'ERROR' | 'DEFAULT'
  helpText: string
}

const INITIAL_USER_NAME_STATE: UserName = {
  value: '',
  status: 'DEFAULT',
  helpText: '예약 캘린더에 노출할 이름을 입력해 주세요.',
}
function useHandleUserName() {
  const [userName, setUserName] = useState<UserName>(INITIAL_USER_NAME_STATE)

  const validateUserName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const isSuccess = nameRegex.test(value)

    if (value === '') {
      setUserName(INITIAL_USER_NAME_STATE)
    } else {
      setUserName({
        value,
        status: isSuccess ? 'SUCCESS01' : 'ERROR',
        helpText: isSuccess
          ? '사용할 수 있는 이름입니다.'
          : '50자 이상의 텍스트, 특수 문자는 입력할 수 없습니다.',
      })
    }
  }

  return {
    userName,
    validateUserName,
  }
}

export default useHandleUserName
