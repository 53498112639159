import { SubTitleLCss, SubTitleM24Css } from 'components/common/SubTitle/SubTitle.styles'
import {
  TitleLCss,
  TitleM24Css,
  TitleXLCss,
  TitleXXLCss,
} from 'components/common/Title/Title.styles'
import styled, { css } from 'styled-components'
export { ReactComponent as AuthRegisterRolesStoreIcon } from 'assets/images/roles/roles-icon-store.svg'
export { ReactComponent as AuthRegisterRolesEmployeeIcon } from 'assets/images/roles/roles-icon-employee.svg'

export const AuthRegisterRolesContentWrapper = styled.div`
  width: 100%;
  padding: 88px 0;

  ${({ theme }) => css`
    & .color-gray-300 {
      color: ${theme.color.gray[300]};
    }
    & .color-gray-400 {
      color: ${theme.color.gray[400]};
    }
    & .color-gray-500 {
      color: ${theme.color.gray[500]};
    }

    ${theme.breakpoint.mediaQuery.down('md')} {
      padding: 0;
    }
  `}
`

export const AuthRegisterRolesContentBox = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.color.gray[0]};
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 3px 10px 0 ${theme.color.shadow[1]};

    ${theme.breakpoint.mediaQuery.down('md')} {
      min-height: 100vh;
      max-width: unset;
      box-shadow: unset;
    }
  `}
`

export const AuthRegisterRolesTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px auto 56px;
  text-align: center;

  ${({ theme }) => css`
    ${theme.breakpoint.mediaQuery.down('md')} {
      margin-top: 44px;
    }
  `}
`

export const AuthRegisterRolesTitleIconWrapper = styled.span`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background: ${theme.color.primary[300]};
    & svg path {
      fill: ${theme.color.gray[0]};
    }

    ${theme.breakpoint.mediaQuery.down('md')} {
      width: 40px;
      height: 40px;

      & svg {
        width: 21px;
        height: 21px;
      }
    }
  `}
`

export const AuthRegisterRolesTitle = styled.h2`
  ${({ theme }) => css`
    ${TitleXXLCss};

    ${theme.breakpoint.mediaQuery.down('md')} {
      ${TitleXLCss};
    }
  `}
`

export const AuthRegisterRolesSubTitle = styled.p`
  ${({ theme }) => css`
    max-width: 292px;

    ${SubTitleLCss};

    & .highlight {
      ${TitleLCss};
    }

    ${theme.breakpoint.mediaQuery.down('md')} {
      max-width: 256px;

      ${SubTitleM24Css};

      & .highlight {
        ${TitleM24Css};
      }
    }
  `}
`

export const AuthRegisterRolesRoleWrapper = styled.div`
  display: flex;
  gap: 16px;
  ${({ theme }) => css`
    ${theme.breakpoint.mediaQuery.down('md')} {
      flex-direction: column;
    }
  `}
`

export const AuthRegisterRolesRoleItem = styled.button`
  ${({ theme }) => css`
    width: 50%;
    border: 1px solid ${theme.color.gray[50]};
    padding: 24px;
    border-radius: 16px;
    gap: 24px;
    display: flex;
    flex-direction: column;
    text-align: left;

    &:hover {
      border-color: ${theme.color.primary[300]};
    }
    svg {
      width: 48px;
      height: 48px;
    }

    ${theme.breakpoint.mediaQuery.down('md')} {
      flex-direction: row;
      width: 100%;
    }
  `}
`

export const AuthRegisterRolesRoleItemTitle = styled.h3`
  ${({ theme }) => css`
    ${TitleXLCss};
    ${theme.breakpoint.mediaQuery.down('md')} {
      ${TitleLCss};
    }
  `}
`

export const AuthRegisterRolesContentFooter = styled.div`
  padding-top: 214px;
  text-align: center;
  ${({ theme }) => css`
    ${theme.breakpoint.mediaQuery.down('md')} {
      padding-top: 56px;
      margin-top: auto;
    }
  `}
`
