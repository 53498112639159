import cn from 'classnames'
import { SelectOption as SelectOptionType } from './Select.types'
import { StyledOption, StyledOptionText } from './SelectOption.styles'

type Props = {
	option: SelectOptionType
	selected: boolean
	children: React.ReactNode
	onChangeValue: (value: SelectOptionType) => void
}

function SelectOption({ option, selected, onChangeValue }: Props) {
	return (
		<StyledOption className={cn({ selected })} type="button" onClick={() => onChangeValue(option)}>
			<StyledOptionText>{option.name}</StyledOptionText>
		</StyledOption>
	)
}

export default SelectOption
