import { applyShorteningWords } from 'utils/common/styles'
import styled, { css } from 'styled-components'
import Icon from '../Icon'
import SubTitle from '../SubTitle'

export const SelectDisplayIcon = styled(Icon).attrs({
  icon: 'dropdownDefault',
  className: 'select-display-icon',
})`
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
`
export const SelectDisplayWrapper = styled.button.attrs({
  className: 'select-display',
})`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 12px 35px 12px 16px;
  color: inherit;
  text-align: left;

  ${({ theme }) => css`
    color: ${theme.color.gray[300]};
  `}
`

export const SelectDisplayText = styled(SubTitle.M20)`
  ${applyShorteningWords()}
`
