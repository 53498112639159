import styled from 'styled-components'
import { css } from 'styled-components'

export const SelectOptionListWrapper = styled.ul`
	position: absolute;
	top: 100%;
	left: -1px;
	width: calc(100% + 2px);
	max-height: 196px;
	z-index: 99;
	overflow-y: auto;

	${({ theme }) => css`
		border: 1px solid ${theme.color.primary[300]};
		border-top: none;
		background-color: ${theme.color.gray[0]};
		border-bottom-left-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
	`}
`
