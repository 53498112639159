import React, { useEffect, useRef } from 'react'
import { SelectOption as SelectOptionType, SelectValue } from './Select.types'
import useLockBodyScrollWhenScrollTarget from 'hooks/useLockBodyScrollWhenScrollTarget'
import { SelectOptionListWrapper } from './SelectOptionList.styles'
import SelectOption from './SelectOption'

type Props = {
  show?: boolean
  options: SelectOptionType[]
  selectedValue?: SelectValue
  initialFocusValue?: SelectValue
  onChangeValue: (option: SelectOptionType) => void
}

function SelectOptionList({
  show,
  selectedValue,
  options = [],
  initialFocusValue,
  onChangeValue,
}: Props) {
  const listRef = useRef<HTMLUListElement>(null)
  const { register: registerLockBodyScrollWhenScrollOption, unLock: unLockBodyScroll } =
    useLockBodyScrollWhenScrollTarget()

  useEffect(() => {
    const listEl = listRef.current

    if (!listEl) return

    const optionEls = listEl.querySelectorAll('li > button')
    const { top: listElTop } = listEl.getBoundingClientRect()

    Array.from(optionEls).forEach((optionItemEl) => {
      if (optionItemEl?.classList.contains('selected')) {
        const { top } = optionItemEl.getBoundingClientRect()

        listEl.scrollTo({
          top: top - listElTop,
        })
      }
    })

    if (!show) {
      unLockBodyScroll()
    }

    return () => {
      unLockBodyScroll()
    }
  }, [show])

  if (!show || options.length === 0) return null

  return (
    <SelectOptionListWrapper ref={listRef} {...registerLockBodyScrollWhenScrollOption}>
      {options.map((option) => (
        <li key={option.value}>
          <SelectOption
            selected={applySelectedStyle({
              selectedValue,
              initialFocusValue,
              option,
            })}
            option={option}
            onChangeValue={onChangeValue}
          >
            {option.name}
          </SelectOption>
        </li>
      ))}
    </SelectOptionListWrapper>
  )
}

export default SelectOptionList

function applySelectedStyle({
  selectedValue,
  initialFocusValue,
  option,
}: Pick<Props, 'selectedValue' | 'initialFocusValue'> & { option: SelectOptionType }) {
  if (selectedValue === option.value) {
    return true
  }

  if (selectedValue === undefined && initialFocusValue === option.value) {
    return true
  }

  return false
}
