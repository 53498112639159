import { forwardRef } from 'react'
import { StyledInput } from './Input.styles'
import { InputProps } from './Input.types'

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ isRequired, size, borderType, status = 'DEFAULT', ...props }, ref) => {
    const getStatus = () => {
      switch (status) {
        case 'DEFAULT':
          return props.value ? 'SUCCESS01' : 'DEFAULT'
        case 'READONLY':
        case 'DISABLED':
          return status
        default:
          return props.value ? status : 'DEFAULT'
      }
    }
    return (
      <StyledInput
        required={isRequired}
        disabled={status === 'DISABLED' || props.disabled}
        $borderType={borderType}
        $size={size}
        status={getStatus()}
        {...props}
        ref={ref}
      />
    )
  },
)

Input.displayName = 'Input'

export default Input
