import styled, { css } from 'styled-components'

type FormLabelPropsType = {
  isRequired?: boolean
}

const FormLabel = styled.label<FormLabelPropsType>`
  display: block;
  font-weight: 500;

  ${({ isRequired, theme }) =>
    isRequired &&
    css`
      &::after {
        content: '*';
        display: inline-block;
        letter-spacing: -0.5px;
        font-size: 12px;
        vertical-align: top;
        color: ${theme.color.red['300']};
        margin-left: 4px;
      }
    `}
`

export default FormLabel
