import { SelectActions, SelectState } from './Select.types'

export function selectReducer(state: SelectState, action: SelectActions): SelectState {
	switch (action.type) {
		case 'SHOW_DEFAULT_DISPLAY_NAME':
			return {
				...state,
				status: action.type,
				selectedValue: undefined,
			}
		case 'SHOW_DEFAULT_VALUE':
			return {
				...state,
				status: action.type,
				selectedValue: action.payload.value,
			}
		case 'SHOW_PLACEHOLDER':
			return {
				...state,
				status: action.type,
				selectedValue: undefined,
			}
		case 'SHOW_SELECTED_VALUE':
			return {
				...state,
				status: action.type,
				isActiveOptionList: false,
			}
		case 'SELECT_OPTION':
			return {
				...state,
				selectedValue: action.payload.value,
				status: 'SHOW_SELECTED_VALUE',
				isActiveOptionList: false,
			}
		case 'ACTIVE_OPTION_LIST':
			return {
				...state,
				isActiveOptionList: true,
			}
		case 'INACTIVE_OPTION_LIST':
			return {
				...state,
				isActiveOptionList: false,
			}
		case 'TOGGLE_OPTION_LIST':
			return {
				...state,
				isActiveOptionList: !state.isActiveOptionList,
			}
		default:
			return state
	}
}
