import dayjs from 'dayjs'
import 'dayjs/locale/ko'

export type TimeType = {
  time: string
  display: string
}

export const buildTimeInfo = (hour: number, minute: number) => {
  if (hour === 24) {
    const displayMinute = minute < 10 ? '0' + minute : minute
    return {
      time: '' + hour + ':' + displayMinute,
      display: '오전 12:' + displayMinute,
    }
  }

  const locale = 'ko-KR'
  const date = dayjs().locale(locale).set('hour', hour).set('minute', minute)
  return {
    time: date.format('HH:mm'),
    display: formatDisplay(date),
  }
}

const formatDisplay = (date: dayjs.Dayjs) => {
  return date.format('a h:mm')
}

const createTimesByTimeUnit = (timeUnit: number) => {
  const times: TimeType[] = []
  const SECONDS_IN_MINUTE = 60
  for (
    let i = 0, timeInterval = 0;
    timeInterval <= 24 * SECONDS_IN_MINUTE;
    i++, timeInterval += timeUnit
  ) {
    const hour = Math.floor(timeInterval / SECONDS_IN_MINUTE)
    const minute = timeInterval % SECONDS_IN_MINUTE
    times.push(buildTimeInfo(hour, minute))
  }
  return times
}

const timesBy10Min = createTimesByTimeUnit(10)
const timesBy30Min = createTimesByTimeUnit(30)

export const getTimesByTimeUnit = (timeUnit: number) =>
  timeUnit === 10 ? timesBy10Min : timesBy30Min

export const getTimeByTimeUnitAndStartEndTime = (
  timeUnit: number,
  startTime: string,
  endTime: string,
) => {
  return getTimesByTimeUnit(timeUnit).filter(
    (timeElement) => startTime <= timeElement.time && timeElement.time <= endTime,
  )
}

const createTimeDurationByTimeUnit = (timeUnit: number) => {
  return timeUnit === 10
    ? Array.from({ length: 24 * 6 }).map((_, i) => `${`0${~~(i / 6)}`.slice(-2)}:${i % 6}0`)
    : Array.from({ length: 24 * 2 }).map((_, i) => `${`0${~~(i / 2)}`.slice(-2)}:${(i % 2) * 3}0`)
}

const durationsBy10Min = createTimeDurationByTimeUnit(10)
const durationsBy30Min = createTimeDurationByTimeUnit(30)

export const getTimeDurationByTimeUnit = (timeUnit: number) =>
  timeUnit === 10 ? durationsBy10Min : durationsBy30Min
