import styled, { css } from 'styled-components'

export const DateChecksBlock = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 12px;
`

export const DateChecksItem = styled.div`
  flex: 1;
  position: relative;
  max-height: 80px;
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

export const DateLabel = styled.label`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
    text-align: center;
    box-sizing: border-box;
    color: ${theme.color.gray[400]};
    background: ${theme.color.bg[200]};
    cursor: pointer;
  `}
`

export const DateCheck = styled.input`
  ${({ theme }) => css`
    display: none;
    &:checked ~ label {
      background-color: ${theme.color.primary[300]};
      color: ${theme.color.gray[0]};
    }
  `}
`
