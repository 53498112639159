import React from 'react'
import { Navigate, useLocation, createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import MainTemplate from 'template/MainTemplate'

import { RegisterPage } from 'pages/auth/Register'
import { RegisterOwnerPage } from 'pages/auth/Register/Owner'
import { RegisterRolesPage } from 'pages/auth/Register/Roles'
import { RegisterEmployeePage } from 'pages/auth/Register/Employee'
import { RegisterSuccessPage } from 'pages/auth/Register/Success'

import ErrorBoundary from 'components/error/ErrorBoundary'

/* router data */
const browseRouter = createBrowserRouter([
  {
    path: '/register',
    element: (
      <PageScrollTopTemplate>
        <RegisterPage />
      </PageScrollTopTemplate>
    ),
  },
  {
    path: '/register/roles',
    element: (
      <PageScrollTopTemplate>
        <RegisterRolesPage />
      </PageScrollTopTemplate>
    ),
  },
  {
    path: '/register/owner',
    element: (
      <PageScrollTopTemplate>
        <RegisterOwnerPage />
      </PageScrollTopTemplate>
    ),
  },
  {
    path: '/register/employee',
    element: (
      <PageScrollTopTemplate>
        <RegisterEmployeePage />
      </PageScrollTopTemplate>
    ),
  },
  {
    path: '/register/employee/success',
    element: (
      <PageScrollTopTemplate>
        <RegisterSuccessPage />
      </PageScrollTopTemplate>
    ),
  },
  {
    path: '*',
    element: <Navigate to={'/register'} />,
  },
])
const App: React.FC<{}> = () => {
  return (
    <MainTemplate>
      <Helmet>
        <title>공비서 CRM</title>
        <meta name="description" content="공비서 CRM" />
        <link
          rel="preload"
          href="/ssr/static/fonts/Pretendard-Regular.subset.woff2"
          as="font"
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/ssr/static/fonts/Pretendard-Medium.subset.woff2"
          as="font"
          type="font/woff2"
        />
        <link
          rel="preload"
          href="/ssr/static/fonts/Pretendard-Bold.subset.woff2"
          as="font"
          type="font/woff2"
        />
        <link rel="stylesheet" href="/ssr/static/styles/font.css" />
      </Helmet>
      <ErrorBoundary>
        <RouterProvider router={browseRouter} />
      </ErrorBoundary>
    </MainTemplate>
  )
}

export default App

function PageScrollTopTemplate({ children }: { children: React.ReactNode }) {
  const location = useLocation()

  React.useEffect(() => {
    document.body.scrollTo({ top: 0 })
  }, [location])

  return <>{children}</>
}
