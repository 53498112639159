import * as React from 'react'
import { PathRouteProps } from 'react-router-dom'
import qs from 'qs'
import axios from 'axios'
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form'
import { useSetRecoilState } from 'recoil'
import { useLocation, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { InputGroup, InputRightElement, Link } from '@chakra-ui/react'

import { EyeIcon, EyeOffIcon } from 'components/Icons/svg'

import { registerUser } from 'services/auth.service'

import { authAtom } from 'stores/atoms'
import { queryPramsAtom } from 'stores/atoms/users'
import { TextField, Title } from 'components/common'
import { useHandlePassword } from 'hooks/useHandlePassword'
import { useHandleUserId } from 'hooks/useHandleUserId'
import useHandleUserName from 'hooks/useHandleUserName'
import FullContent from 'template/FullContent'
import FormLabel from 'components/common/FormLabel'
import {
  AuthRegisterContentBox,
  AuthRegisterContentWrapper,
  AuthRegisterFormButtonWrapper,
  AuthRegisterFormItem,
  AuthRegisterFormWrapper,
  AuthRegisterTermsText,
  AuthRegisterTermsTextBr,
} from './AuthRegister.styles'
import Spacing from 'components/common/Spacing'
import MobileTopbar from 'components/common/MobileTopbar'
import Breakpoint from 'components/common/Breakpoint'
import AuthPhoneNumberForm, {
  ExportAuthPhoneNumberFormHandlers,
} from 'components/AuthPhoneNumberForm'
import { COMMON_ERROR_TEXT } from 'constants/common/text'
import useBeforeUnload from 'hooks/useBeforeUnload'
import useDidMountEffect from 'hooks/useDidMountEffect'
import Button from 'components/common/Button'

interface RegisterPageProps extends PathRouteProps {}

export const RegisterPage: React.FC<RegisterPageProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const setAuthState = useSetRecoilState(authAtom)
  const setQueryParams = useSetRecoilState(queryPramsAtom)

  const authPhoneNumberFormRef = React.useRef<ExportAuthPhoneNumberFormHandlers>(null)
  const [mobilePhoneNumber, setMobilePhoneNumber] = React.useState('')
  const [isCertificated, setIsCertificated] = React.useState(false)

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onBlur',
  })

  const { userId, onChangeUserId } = useHandleUserId()
  const {
    password,
    passwordConfirm,
    getPasswordConfirmStatus,
    getPasswordStatus,
    getHelptext,
    onChangePassword,
    onChangePasswordConfirm,
    isShowErrorText,
    showPassword,
    showPasswordConfirm,
    onChangePasswordConfirmType,
    onChangePasswordType,
  } = useHandlePassword()
  const { userName, validateUserName } = useHandleUserName()

  const setBeforeUnload = useBeforeUnload({ useBeforeUnload: false })

  useDidMountEffect(() => {
    setBeforeUnload(true)
  }, [userId.value, password, passwordConfirm, userName.value, mobilePhoneNumber])

  const isValidAll =
    isCertificated &&
    [userId.status, getPasswordStatus(), getPasswordConfirmStatus(), userName.status].every(
      (status) => status === 'SUCCESS01',
    )

  const qsData = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  React.useEffect(() => {
    const { returnUrl } = qsData
    if (Object.keys(qsData).length) {
      setQueryParams({
        returnUrl,
      })
    }
  }, [qsData, setQueryParams])

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (!isValidAll) {
      return
    }

    const payload = {
      id: userId.value,
      mobilePhoneNumber,
      name: userName.value,
      password,
    }
    try {
      const {
        data: { data, errorResponse },
      } = await registerUser(payload)

      if (errorResponse.status !== 200) {
        alert(errorResponse.message)
        return
      }

      setAuthState(data)
      setBeforeUnload(false)
      return navigate('/register/roles')
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // 이미 가입된 휴대폰 번호
        if (error.response?.data?.code === 'DUPLICATE_MOBILE_PHONE_NUMBER') {
          authPhoneNumberFormRef.current?.authPhoneNumberAction({ type: 'DEFAULT' })
        }
        alert(error.response?.data.message)
      } else {
        alert(COMMON_ERROR_TEXT)
      }
    }
  }

  return (
    <FullContent>
      <FullContent.Header>
        <Breakpoint.Up size="md">
          <FullContent.HeaderTitle icon="reserveStateCustomer" title="회원가입" />
        </Breakpoint.Up>
        <Breakpoint.Down size="md">
          <MobileTopbar centerContent={<Title.L>회원가입</Title.L>} />
        </Breakpoint.Down>
      </FullContent.Header>
      <FullContent.Content>
        <AuthRegisterContentWrapper>
          <AuthRegisterContentBox>
            <StyledForm onSubmit={handleSubmit(onSubmit)}>
              <AuthRegisterFormWrapper>
                <AuthRegisterFormItem>
                  <FormLabel isRequired>아이디</FormLabel>
                  <TextField
                    autoComplete="off"
                    placeholder="아이디를 입력해 주세요."
                    status={userId.status}
                    helpText={userId.helpText}
                    value={userId.value}
                    onChange={onChangeUserId}
                  />
                </AuthRegisterFormItem>
                <AuthRegisterFormItem>
                  <FormLabel isRequired>비밀번호</FormLabel>
                  <InputGroup size="md">
                    <TextField
                      autoComplete="new-password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="비밀번호를 입력해 주세요."
                      status={getPasswordStatus()}
                      value={password}
                      onChange={onChangePassword}
                    />
                    <InputRightElement
                      width="2.75rem"
                      onClick={onChangePasswordType}
                      cursor="pointer"
                    >
                      {showPassword ? <EyeIcon /> : <EyeOffIcon />}
                    </InputRightElement>
                  </InputGroup>
                  <InputGroup size="md">
                    <TextFieldPasswordConfirm
                      autoComplete="new-password"
                      type={showPasswordConfirm ? 'text' : 'password'}
                      placeholder="비밀번호를 한 번 더 입력해 주세요."
                      status={getPasswordConfirmStatus()}
                      value={passwordConfirm}
                      helpText={getHelptext()}
                      onChange={onChangePasswordConfirm}
                      isShowErrorText={isShowErrorText}
                    />
                    <InputRightElement
                      width="2.75rem"
                      onClick={onChangePasswordConfirmType}
                      cursor="pointer"
                    >
                      {showPasswordConfirm ? <EyeIcon /> : <EyeOffIcon />}
                    </InputRightElement>
                  </InputGroup>
                </AuthRegisterFormItem>
                <AuthRegisterFormItem>
                  <FormLabel isRequired>이름</FormLabel>
                  <TextField
                    placeholder="이름을 입력해 주세요."
                    status={userName.status}
                    value={userName.value}
                    helpText={userName.helpText}
                    onChange={validateUserName}
                  />
                </AuthRegisterFormItem>
                <AuthRegisterFormItem>
                  <AuthPhoneNumberForm
                    ref={authPhoneNumberFormRef}
                    mobilePhoneNumber={mobilePhoneNumber}
                    handleChangeMobilePhoneNumber={(phoneNumber) => {
                      setMobilePhoneNumber(phoneNumber)
                    }}
                    isCertificated={isCertificated}
                    handleChangeIsCertificated={(isCertificated) => {
                      setIsCertificated(isCertificated)
                    }}
                  />
                </AuthRegisterFormItem>
              </AuthRegisterFormWrapper>
              <AuthRegisterFormButtonWrapper>
                <AuthRegisterTermsText>
                  공비서 원장님 회원가입을 완료하면 공비서 원장님, 공비서스토어의{' '}
                  <AuthRegisterTermsTextBr />
                  <Link
                    _active={{ boxShadow: 'none' }}
                    href="https://www.gongbiz.kr/crm/terms.html"
                    isExternal
                    textDecoration="underline"
                  >
                    통합 서비스 이용약관
                  </Link>{' '}
                  및{' '}
                  <Link
                    _active={{ boxShadow: 'none' }}
                    href="https://www.gongbiz.kr/crm/privacy.html"
                    isExternal
                    textDecoration="underline"
                  >
                    개인정보 처리방침
                  </Link>
                  에 동의하신 것으로 간주합니다.
                </AuthRegisterTermsText>
                <Spacing size="8" />
                <Button
                  variant="blue"
                  isLoading={isSubmitting}
                  stretch
                  size="large"
                  type="submit"
                  disabled={!isValidAll}
                >
                  회원가입 완료
                </Button>
              </AuthRegisterFormButtonWrapper>
            </StyledForm>
          </AuthRegisterContentBox>
        </AuthRegisterContentWrapper>
      </FullContent.Content>
    </FullContent>
  )
}

const StyledForm = styled.form`
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input::placeholder {
    color: ${({ theme }) => theme.color.gray[300]};
  }
`

const TextFieldPasswordConfirm = styled(TextField)<{ isShowErrorText: boolean }>`
  ${({ theme, isShowErrorText }) => {
    if (isShowErrorText) {
      return css`
        & + p {
          color: ${theme.color.red[300]};
        }
      `
    }
  }}
`
