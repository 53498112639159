import styled from 'styled-components'
import { ButtonProps } from './Button.types'
import setButtonStyle from './styles'
import Icon from '../Icon'

const defaultProps: Pick<Required<ButtonProps>, 'variant' | 'size' | 'as'> = {
	variant: 'white',
	size: 'medium',
	as: 'button',
}

const Button = ({ disabled, isLoading, ...props }: ButtonProps) => {
	return (
		<StyledButton disabled={disabled || isLoading} {...props}>
			{isLoading ? <Icon icon="spinner" className="loadingSpinner" /> : props.children}
		</StyledButton>
	)
}

export default Button

const StyledButton = styled.button<ButtonProps>`
	${(props) =>
		setButtonStyle({
			...defaultProps,
			...props,
		})}

	.loadingSpinner {
		width: 1.5em;
		height: 1.5em;
	}
`
