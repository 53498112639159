import { HTMLAttributes } from 'react'
import * as icons from './svgr'
import { CSSProperties } from 'styled-components'

export type IconType = keyof typeof icons

export const IconNames = Object.keys(icons) as IconType[]

type IconProps = HTMLAttributes<SVGElement> & {
  icon: IconType
  css?: CSSProperties
  className?: string
  size?: number
}

function Icon({ icon, size, ...props }: IconProps) {
  const IconComponent = icons[icon] ?? NotFoundIcon

  const iconSizeStyle = size
    ? {
        width: size,
        height: size,
      }
    : {}

  return <IconComponent {...iconSizeStyle} {...props} />
}

export default Icon

function NotFoundIcon({ icon }: IconProps) {
  return <i data-testid={icon}>{icon}을 찾을 수 없습니다.</i>
}
