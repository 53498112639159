import { Flex, Title } from 'components/common'
import Icon, { IconType } from 'components/common/Icon'

type HeaderTitleProps = {
  icon: IconType
  title: string
}

const HeaderTitle = ({ icon, title }: HeaderTitleProps) => {
  return (
    <Flex gap={8} alignItems="center">
      <Icon icon={icon} size={24} />
      <Title.L>{title}</Title.L>
    </Flex>
  )
}

export default HeaderTitle
