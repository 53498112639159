import { useRef, forwardRef, useReducer, useImperativeHandle, useEffect } from 'react'
import classNames from 'classnames'
import { SelectWrapper } from './Select.styles'
import {
  DefaultShowActionCreator,
  SelectOption,
  SelectState,
  SelectValue,
  ShowActionCreator,
  ShowDisplayStatus,
} from './Select.types'
import SelectDisplay from './SelectDisplay'
import SelectOptionList from './SelectOptionList'
import { selectReducer } from './selectReducer'
import useClickOutOfArea from 'hooks/useClickOutOfArea'
import isNullish from 'utils/common/isNullish'

export type ExportSelectHandlers = {
  changeValue: (value: SelectValue) => void
  changeDisplayStatus: (status: ShowDisplayStatus) => void
}
export type { SelectValue, SelectOption }

type SelectProps = {
  /** Select 컴포넌트의 name attribute 입니다. */
  name: string
  /** 사용자가 값을 입력하기 전에 표시되는 짧은 문구입니다. */
  placeholder?: string
  /** `true` 인 경우 Select 컴포넌트가 비활성화 상태가 됩니다. */
  disabled?: boolean
  /** 외부에서 값을 동적으로 변경하기 위해서 사용합니다 */
  value?: SelectValue
  /** Select 컴포넌트의 옵션 리스트입니다. */
  options?: SelectOption[]
  /** 옵션이 선택되기 전, Select 컴포넌트에 기본으로 표시할 값입니다. 최초 렌더링 된 이후에는 설정할 수 없습니다. */
  defaultDisplayName?: string
  /** 옵션이 선택되기 전, Select 컴포넌트에 기본으로 선택되어있는 value 값입니다. 최초 렌더링 된 이후에는 설정할 수 없습니다. */
  defaultValue?: SelectValue
  /** 옵션 아이템이 선택되면 호출되는 `callback` 입니다. parameter엔 `name` 과 선택된 `value` 값이 있습니다.*/
  onChangeValue?: (name: string, value: SelectValue) => void
  /** Select 컴포넌트의 기본 액션 타입을 지정합니다.  */
  defaultActionType?: DefaultShowActionCreator['type']
  /** 옵션이 선택되기 전, Select 컴포넌트에 선택되어 있는 value는 아니지만 옵션리스트를 열었을 때 포커스 되어 있는 값입니다. */
  initialFocusValue?: SelectValue
}

const initialSelectState: SelectState = {
  isActiveOptionList: false,
  status: 'SHOW_PLACEHOLDER',
}

/**
 * `Select` 컴포넌트는 사용자에게 리스트 중 필요한 옵션 선택 시 제공하는 컴포넌트입니다.
 */
const Select = forwardRef<ExportSelectHandlers, SelectProps>(
  (
    {
      name,
      disabled,
      defaultDisplayName = '',
      defaultValue = '',
      placeholder = '',
      options = [],
      value,
      onChangeValue,
      defaultActionType,
      initialFocusValue,
    },
    ref,
  ) => {
    const [state, dispatch] = useReducer(
      selectReducer,
      selectReducer(
        initialSelectState,
        getInitialSelectAction({
          options,
          defaultDisplayName,
          disabled,
          defaultValue,
          defaultActionType,
        }),
      ),
    )
    const ACTIVE_STATUS: ShowDisplayStatus[] = [
      'SHOW_DEFAULT_DISPLAY_NAME',
      'SHOW_SELECTED_VALUE',
      'SHOW_DEFAULT_VALUE',
    ]
    const SHOW_DISPLAY_NAME: Record<ShowDisplayStatus, string> = {
      SHOW_PLACEHOLDER: placeholder,
      SHOW_DEFAULT_DISPLAY_NAME: defaultDisplayName,
      SHOW_DEFAULT_VALUE: options.find((option) => option.value === defaultValue)?.name ?? '',
      SHOW_SELECTED_VALUE:
        options.find((option) => option.value === state.selectedValue)?.name ?? '',
    }
    const isActiveOptionList = disabled ? false : state.isActiveOptionList
    const selectElRef = useRef<HTMLDivElement>(null)

    useClickOutOfArea({
      target: selectElRef,
      callback() {
        dispatch({
          type: 'INACTIVE_OPTION_LIST',
        })
      },
    })
    useEffect(() => {
      if (!isNullish(value)) {
        dispatch({
          type: 'SELECT_OPTION',
          payload: {
            value,
          },
        })
      }
    }, [value])
    useImperativeHandle(
      ref,
      () => ({
        changeValue: (value: SelectValue) => {
          dispatch({
            type: 'SELECT_OPTION',
            payload: {
              value,
            },
          })

          const option = options.find((option) => option.value === value)

          if (option) {
            onChangeValue?.(name, option.value)
          }
        },
        changeDisplayStatus: (status: ShowDisplayStatus) => {
          switch (status) {
            case 'SHOW_DEFAULT_DISPLAY_NAME':
            case 'SHOW_SELECTED_VALUE':
            case 'SHOW_PLACEHOLDER':
              dispatch({
                type: status,
              })
              break
            case 'SHOW_DEFAULT_VALUE':
              dispatch({
                type: status,
                payload: {
                  value: defaultValue,
                },
              })
          }
        },
      }),
      [options, defaultValue],
    )

    const handleChangeValue = (option: SelectOption) => {
      dispatch({
        type: 'SELECT_OPTION',
        payload: {
          value: option.value,
        },
      })
      onChangeValue?.(name, option.value)
    }
    const handleToggleOptionList = () => {
      dispatch({
        type: 'TOGGLE_OPTION_LIST',
      })
    }

    return (
      <SelectWrapper
        id={name}
        data-testid="select-wrapper"
        className={classNames({
          show: isActiveOptionList,
          disabled,
          active: ACTIVE_STATUS.includes(state.status),
        })}
        ref={selectElRef}
      >
        <SelectDisplay
          displayName={SHOW_DISPLAY_NAME[state.status]}
          onToggleOptionList={handleToggleOptionList}
        />
        <SelectOptionList
          show={isActiveOptionList}
          options={options}
          selectedValue={state.selectedValue}
          initialFocusValue={initialFocusValue}
          onChangeValue={handleChangeValue}
        />
      </SelectWrapper>
    )
  },
)

Select.displayName = 'Select'

export default Select

type SetInitialStateParams = {
  options: SelectOption[]
  defaultDisplayName?: string
  defaultValue?: SelectValue
  disabled?: boolean
  defaultActionType?: DefaultShowActionCreator['type']
}

export function getInitialSelectAction({
  options,
  defaultDisplayName,
  defaultValue,
  disabled,
  defaultActionType,
}: SetInitialStateParams): ShowActionCreator {
  const CONDS: [() => boolean, ShowActionCreator][] = [
    [
      () =>
        defaultActionType === 'SHOW_DEFAULT_VALUE' &&
        options.some((option) => option.value === defaultValue),
      {
        type: 'SHOW_DEFAULT_VALUE',
        payload: {
          value: options.find((option) => option.value === defaultValue)?.value,
        },
      },
    ],
    [
      () => !!disabled,
      {
        type: 'SHOW_PLACEHOLDER',
      },
    ],
    [
      () => options.some((option) => option.value === defaultValue),
      {
        type: 'SHOW_DEFAULT_VALUE',
        payload: {
          value: options.find((option) => option.value === defaultValue)?.value,
        },
      },
    ],
    [
      () => !!defaultDisplayName,
      {
        type: 'SHOW_DEFAULT_DISPLAY_NAME',
      },
    ],
  ]

  const [, action] = CONDS.find(([cond]) => cond()) ?? []

  if (action) {
    return action
  }

  return {
    type: 'SHOW_PLACEHOLDER',
  }
}
