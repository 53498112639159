import { ReactNode } from 'react'
import { HeaderContainer, HeaderWrapper } from './Header.styles'

const Header = ({ children }: { children: ReactNode }) => {
  return (
    <HeaderContainer>
      <HeaderWrapper>{children}</HeaderWrapper>
    </HeaderContainer>
  )
}

export default Header
