import styled, { css } from 'styled-components'

export const SubTitleXXLCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xxl};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.xxl};
  `}
`

export const SubTitleXLCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xl};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.xl};
  `}
`

export const SubTitleLCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.l};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.l};
  `}
`

export const SubTitleM24Css = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.m};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.l};
  `}
`

export const SubTitleM20Css = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.m};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.m};
  `}
`

export const SubTitleSCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.s};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.s};
  `}
`

export const SubTitleXSCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.medium};
    line-height: ${theme.lineHeight.xs};
  `}
`

const XXL = styled.h1`
  ${SubTitleXXLCss}
`

const XL = styled.h2`
  ${SubTitleXLCss}
`

const L = styled.h3`
  ${SubTitleLCss}
`

const M24 = styled.h4`
  ${SubTitleM24Css}
`

const M20 = styled.h4`
  ${SubTitleM20Css}
`

const S = styled.h5`
  ${SubTitleSCss}
`

const XS = styled.h6`
  ${SubTitleXSCss}
`

export { XXL, XL, L, M24, M20, S, XS }
