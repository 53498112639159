import styled, { css } from 'styled-components'

import { Flex } from 'components/common'

export const FullContentWrapper = styled(Flex)`
  width: 100%;
  min-height: 100vh;
  ${({ theme }) => css`
    background-color: ${theme.color.bg[200]};
    ${theme.breakpoint.mediaQuery.down('md')} {
      background: ${theme.color.gray[0]};
    }
  `}
`
