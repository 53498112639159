import React from 'react'
import { SelectDisplayIcon, SelectDisplayText, SelectDisplayWrapper } from './SelectDisplay.styles'

type Props = {
	displayName: string
	onToggleOptionList: () => void
}

function SelectDisplay({ displayName, onToggleOptionList }: Props) {
	return (
		<SelectDisplayWrapper data-testid="select-toggle-button" type="button" onClick={onToggleOptionList}>
			<SelectDisplayText>{displayName}</SelectDisplayText>
			<SelectDisplayIcon />
		</SelectDisplayWrapper>
	)
}

export default SelectDisplay
