import useBreakpoints from 'hooks/useBreakpoints'
import { PropsWithChildren } from 'react'
import { DefaultTheme } from 'styled-components'

type BreakpointProps = {
  size: keyof DefaultTheme['breakpoint']['size']
}

const Up = ({ size, children }: PropsWithChildren<BreakpointProps>) => {
  const breakpoint = useBreakpoints()
  return <>{breakpoint.up(size) ? children : null}</>
}

const Down = ({ size, children }: PropsWithChildren<BreakpointProps>) => {
  const breakpoint = useBreakpoints()
  return <>{breakpoint.down(size) ? children : null}</>
}

const Breakpoint = {
  Up,
  Down,
}

export default Breakpoint
