import React from 'react'
import ReactDOM from 'react-dom'
import {
  ModalWrapper,
  Backdrop,
  ModalBlock,
  ModalHeader,
  CloseButton,
  ModalContent,
  HeaderTitle,
} from './Modal.styles'
import { ModalProps } from './Modal.types'

import Icon from 'components/common/Icon'

export const Modal: React.FC<ModalProps> = ({
  isShown,
  hide,
  modalContent,
  headerText,
  isHideCloseButton,
}) => {
  isShown ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'scroll')

  const modal = (
    <>
      <Backdrop onClick={hide} />
      <ModalWrapper aria-modal tabIndex={-1} role="dialog">
        <ModalBlock>
          <ModalHeader>
            <HeaderTitle>{headerText}</HeaderTitle>
            {!isHideCloseButton && (
              <CloseButton data-dismiss="modal" aria-label="Close" onClick={hide}>
                <Icon size={24} icon="systemX" />
              </CloseButton>
            )}
          </ModalHeader>
          <ModalContent>{modalContent}</ModalContent>
        </ModalBlock>
      </ModalWrapper>
    </>
  )

  return isShown ? ReactDOM.createPortal(modal, document.body) : null
}
