/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'

// 첫번재 렌더링 시에는 호출하지 않고, 두번째 렌더링 이후에만 호출하는 훅
const useDidMountEffect = (func: () => void, deps: React.DependencyList) => {
  const didMount = React.useRef(false)

  React.useEffect(() => {
    if (didMount.current) {
      func()
    } else {
      didMount.current = true
    }
  }, deps)
}

export default useDidMountEffect
