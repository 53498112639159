import { useEffect, useState } from 'react'
import {
  BarberIcon,
  CareIcon,
  EtcIcon,
  EyebrowIcon,
  EyelashIcon,
  FitnessIcon,
  HairIcon,
  MakeupIcon,
  MassageIcon,
  NailIcon,
  PetIcon,
  PilatesIcon,
  SkinIcon,
  TanningIcon,
  TattooIcon,
  WaxingIcon,
} from 'components/Icons/svg/categories'
import { getCategories } from 'services/shop.service'
import { CategoryType } from '../Category/Category.types'

type IconType = Record<string, React.FunctionComponent<React.SVGAttributes<SVGElement>>>

const icons: IconType = {
  NAIL: NailIcon,
  BARBER: BarberIcon,
  MAKEUP: MakeupIcon,
  MASSAGE: MassageIcon,
  SEMI_PERMANENT: EyebrowIcon,
  EYELASHES: EyelashIcon,
  SKINCARE: SkinIcon,
  PET: PetIcon,
  WAXING: WaxingIcon,
  HAIR: HairIcon,
  TATTOO: TattooIcon,
  TANNING: TanningIcon,
  PILATES: PilatesIcon,
  FITNESS: FitnessIcon,
  SCALP_HAIR_CARE: CareIcon,
  ETC: EtcIcon,
}

export const useCategorySelect = () => {
  const [fetchedCategories, setFetchedCategories] = useState<CategoryType[]>([])
  const getDefaultCategories = async () => {
    try {
      const {
        data: {
          data: { categories },
          errorResponse,
        },
      } = await getCategories()

      if (errorResponse.status !== 200) {
        throw new Error(errorResponse.message)
      }

      const result = categories.map((category: { value: string; name: string }, idx: number) => ({
        id: idx,
        name: category.name,
        value: category.value,
        icon: icons[category.name],
        checked: false,
      }))

      setFetchedCategories(result)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getDefaultCategories()
  }, [])

  return {
    fetchedCategories,
  }
}
