import { useEffect, useRef } from 'react'

function useLockBodyScrollWhenScrollTarget() {
  const isScrollLockRef = useRef(false)
  const isFocusRef = useRef(false)

  const touchStartPosYRef = useRef(0)

  const register = {
    onWheel: handleScroll,
    onTouchMove: handleScroll,
    onMouseEnter: activeFocus,
    onMouseLeave: inActiveFocus,
    onTouchStart: handleTouchStart,
    onTouchEnd: inActiveFocus,
  }

  function activeFocus() {
    isFocusRef.current = true
  }
  function inActiveFocus() {
    isFocusRef.current = false
  }

  function activeScrollLock() {
    isScrollLockRef.current = true
  }
  function inActiveScrollLock() {
    isScrollLockRef.current = false
  }

  function handleTouchStart(event: React.TouchEvent<HTMLElement>) {
    activeFocus()
    const startPosY = event.changedTouches[0].screenY
    touchStartPosYRef.current = startPosY
  }

  function handleScroll(event: React.WheelEvent<HTMLElement> | React.TouchEvent<HTMLElement>) {
    const { scrollHeight, scrollTop, clientHeight } = event.currentTarget
    const isScrollStart = scrollTop <= 0
    const isScrollEnd = scrollTop + clientHeight >= scrollHeight

    if (event.type === 'wheel') {
      const wheelEvent = event as React.WheelEvent<HTMLElement>

      const isWheelDown = wheelEvent.deltaY > 0
      const isWheelUp = wheelEvent.deltaY < 0

      if (isScrollEnd && isWheelDown) {
        activeScrollLock()
      } else if (isScrollStart && isWheelUp) {
        activeScrollLock()
      } else {
        inActiveScrollLock()
      }
    }

    if (event.type === 'touchmove') {
      const touchEvent = event as React.TouchEvent<HTMLElement>
      const currentTouchPosY = touchEvent.changedTouches[0].screenY

      const isTouchDown = touchStartPosYRef.current - currentTouchPosY > 0
      const isTouchUp = touchStartPosYRef.current - currentTouchPosY < 0

      if (isScrollEnd && isTouchDown) {
        activeScrollLock()
      } else if (isScrollStart && isTouchUp) {
        activeScrollLock()
      } else {
        inActiveScrollLock()
      }

      touchStartPosYRef.current = currentTouchPosY
    }
  }

  function handleScrollBody(event: Event) {
    if (isScrollLockRef.current && isFocusRef.current && event.cancelable) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    const bodyEl = document.body
    bodyEl.addEventListener('wheel', handleScrollBody, {
      passive: false,
    })
    bodyEl.addEventListener('touchmove', handleScrollBody, {
      passive: false,
    })
    return () => {
      bodyEl.removeEventListener('wheel', handleScrollBody)
      bodyEl.removeEventListener('touchmove', handleScrollBody)
    }
  }, [])

  const unLock = () => {
    inActiveScrollLock()
  }

  return {
    register,
    unLock,
  }
}

export default useLockBodyScrollWhenScrollTarget
