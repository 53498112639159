import { BusinessDaysOfWeekItemType, RegularDayOfWeekType } from 'types/RegisterOwner'

export const INITIAL_BUSINESS_DAYS_OF_WEEK_TAB_TYPE = 'business-type-all'
export const DEFAULT_BUSINESS_DAYS_OF_WEEK_TIME_UNIT = 10
export const INITIAL_BUSNESS_DAYS_OF_WEEK_TIME = {
  startTime: '08:00',
  endTime: '22:00',
}

export const BUSINESS_DAYS_OF_WEEK_KOREAN_MAP = {
  MONDAY: '월',
  TUESDAY: '화',
  WEDNESDAY: '수',
  THURSDAY: '목',
  FRIDAY: '금',
  SATURDAY: '토',
  SUNDAY: '일',
}
export const INITIAL_REGULAR_BUSINESS_DAY_OF_WEEK: RegularDayOfWeekType = {
  monday: true,
  tuesday: true,
  wednesday: true,
  thursday: true,
  friday: true,
  saturday: true,
  sunday: false,
}

export const INITIAL_BUSINESS_DAYS_OF_WEEK: BusinessDaysOfWeekItemType[] = [
  {
    dayOfWeek: 'MONDAY',
    startTime: '08:00',
    endTime: '22:00',
  },
  {
    dayOfWeek: 'TUESDAY',
    startTime: '08:00',
    endTime: '22:00',
  },
  {
    dayOfWeek: 'WEDNESDAY',
    startTime: '08:00',
    endTime: '22:00',
  },
  {
    dayOfWeek: 'THURSDAY',
    startTime: '08:00',
    endTime: '22:00',
  },
  {
    dayOfWeek: 'FRIDAY',
    startTime: '08:00',
    endTime: '22:00',
  },
  {
    dayOfWeek: 'SATURDAY',
    startTime: '08:00',
    endTime: '22:00',
  },
  {
    dayOfWeek: 'SUNDAY',
    startTime: '08:00',
    endTime: '22:00',
  },
]
