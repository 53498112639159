import { ComponentProps } from 'react'
import Icon, { IconType } from '../Icon'
import {
  MobileTopbarColumn,
  MobileTopbarIconButtonWrapper,
  MobileTopbarTitle,
  MobileTopbarWrapper,
} from './MobileTopbar.styles'

type MobileTopbarProps = {
  leftContent?: React.ReactNode
  centerContent?: React.ReactNode
  rightContent?: React.ReactNode
}

function MobileTopbar({ leftContent, centerContent, rightContent }: MobileTopbarProps) {
  return (
    <MobileTopbarWrapper>
      <MobileTopbarColumn column="left">{leftContent}</MobileTopbarColumn>
      <MobileTopbarColumn column="center">{centerContent}</MobileTopbarColumn>
      <MobileTopbarColumn column="right">{rightContent}</MobileTopbarColumn>
    </MobileTopbarWrapper>
  )
}

type MobileTopbarIconButtonProps = {
  icon: IconType
  size?: ComponentProps<typeof Icon>['size']
} & React.ButtonHTMLAttributes<HTMLButtonElement>

function MobileTopbarIconButton({ icon, size = 24, ...buttonProps }: MobileTopbarIconButtonProps) {
  return (
    <MobileTopbarIconButtonWrapper type="button" {...buttonProps}>
      <Icon icon={icon} size={size} />
    </MobileTopbarIconButtonWrapper>
  )
}

export default Object.assign(MobileTopbar, {
  Title: MobileTopbarTitle,
  IconButton: MobileTopbarIconButton,
})
