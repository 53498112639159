import styled, { css } from 'styled-components'
import { InputProps, InputStyleProps } from './Input.types'

export const StyledInput = styled.input<InputStyleProps>`
  outline: none;
  border: none;
  width: 100%;
  appearance: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  ${({ theme, $size, $borderType, status }) => css`
    font-size: ${() => {
      switch ($size) {
        case 'XS':
          return theme.fontSize.s
        case 'S':
        case 'M':
        default:
          return theme.fontSize.m
      }
    }};
    height: ${() => {
      switch ($size) {
        case 'XS':
          return '28px'
        case 'S':
          return '36px'
        case 'M':
        default:
          return '44px'
      }
    }};
    padding: ${$borderType === 'BOX' || status === 'READONLY'
      ? () => {
          switch ($size) {
            case 'XS':
              return '5px 12px'
            case 'S':
              return '8px 16px'
            case 'M':
            default:
              return '12px 16px'
          }
        }
      : 0};
    border-radius: ${$borderType === 'BOX' || status === 'READONLY'
      ? () => {
          switch ($size) {
            case 'XS':
              return '4px'
            case 'S':
            case 'M':
            default:
              return '8px'
          }
        }
      : 0};
    color: ${() => {
      switch (status) {
        case 'DISABLED':
          return theme.color.gray[50]
        default:
          return theme.color.gray[700]
      }
    }};
  `}

  ${({ theme, status }) => css`
    background: ${theme.color.bg[status === 'READONLY' ? 200 : 100]};
    &::placeholder {
      color: ${() => {
        switch (status) {
          case 'READONLY':
            return theme.color.bg[200]
          case 'DISABLED':
            return theme.color.gray[50]
          default:
            return theme.color.gray[300]
        }
      }};
    }
  `}
		

	${({ $borderType, theme, status }) => css`
    ${$borderType === 'BOX' ? 'border' : 'border-bottom'}: 1px solid
		${() => {
      switch (status) {
        case 'SUCCESS01':
        case 'SUCCESS02':
          return theme.color.gray[700]
        case 'ERROR':
          return theme.color.red[300]
        case 'DISABLED':
          return theme.color.gray[50]
        case 'READONLY':
          return theme.color.bg[200]
        default:
          return theme.color.gray[100]
      }
    }};
  `}


	:focus {
    ${({ $borderType, theme, status }) => css`
      ${$borderType === 'BOX' ? 'border' : 'border-bottom'}: 1px solid ${status === 'READONLY'
        ? theme.color.bg[200]
        : theme.color.primary[300]};
    `}
  }

  text-align: ${({ align }) => align};
`
