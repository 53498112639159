import {
  CategoryBlock,
  CategoryInputWrapper,
  CategoryInput,
  CategoryInputRightElement,
  CategorySubText,
  CategoryCotentWrapper,
  CategoryToast,
  CategoryFooterButtonWrapper,
  CategoryCotentContainer,
} from './Category.styles'
import { ChevronDownIcon } from 'components/Icons/svg'
import { useModal } from 'components/Modal/hooks/useModal'
import { Modal } from 'components/Modal'
import { CategoryItems } from '../CategoryItems'
import { CategoryType } from './Category.types'
import Button from 'components/common/Button'
import { useEffect, useRef, useState } from 'react'
import useBreakpoints from 'hooks/useBreakpoints'
import Breakpoint from 'components/common/Breakpoint'

interface CategoryProps {
  fetchedCategories: CategoryType[]
  initialCategories: string[]
  handleUpdateCategories: (categories: string[]) => void
}

const COSMETIC_TOAST_HIDDEN_TIME = 2000

export const Category: React.FC<CategoryProps> = ({
  fetchedCategories,
  initialCategories,
  handleUpdateCategories,
}) => {
  const breakpoint = useBreakpoints()

  const { isShowToast, showToast } = useToast({
    hiddenTime: COSMETIC_TOAST_HIDDEN_TIME,
  })
  const { isShown, toggle } = useModal()

  const [tempSelectedCategories, setTempSelectedCategories] = useState<string[]>(initialCategories)

  const validateUpdateCategoriesForPetShop = (category: CategoryType) => {
    const isPetShopChecked = tempSelectedCategories.some((name) => name === 'PET')

    if (isPetShopChecked && category.name !== 'PET') {
      return false
    }

    if (tempSelectedCategories.length > 0 && !isPetShopChecked && category.name === 'PET') {
      return false
    }

    return true
  }

  const openModal = () => {
    setTempSelectedCategories(initialCategories)
    toggle()
  }
  const handleSave = () => {
    handleUpdateCategories(tempSelectedCategories)
    toggle()
  }
  const handleCancle = () => {
    toggle()
  }

  const content = (
    <CategoryCotentContainer>
      <CategoryCotentWrapper>
        <CategorySubText>
          여러 업종을 선택할 경우, 선택한 순서대로 기본 시술 메뉴가 자동으로 생성됩니다.
        </CategorySubText>
        <CategoryItems
          fetchedCategories={fetchedCategories}
          selectedCategories={tempSelectedCategories}
          onChangeChecked={(category) => {
            if (!validateUpdateCategoriesForPetShop(category)) {
              showToast()
              return
            }

            if (tempSelectedCategories.includes(category.name)) {
              setTempSelectedCategories(
                tempSelectedCategories.filter((cate) => cate !== category.name),
              )
            } else {
              setTempSelectedCategories([...tempSelectedCategories, category.name])
            }
          }}
        />
      </CategoryCotentWrapper>
      <CategoryToast className={isShowToast ? 'show' : ''}>
        애견 업종은 다른 업종과 중복 선택이 불가합니다.
      </CategoryToast>
      <CategoryFooterButtonWrapper>
        <Breakpoint.Up size="md">
          <Button type="button" variant="white" border size="large" stretch onClick={handleCancle}>
            취소
          </Button>
          <Button type="button" variant="black" size="large" stretch onClick={handleSave}>
            저장
          </Button>
        </Breakpoint.Up>
        <Breakpoint.Down size="md">
          <Button type="button" variant="black" size="large" stretch onClick={handleSave}>
            선택
          </Button>
        </Breakpoint.Down>
      </CategoryFooterButtonWrapper>
    </CategoryCotentContainer>
  )

  return (
    <CategoryBlock>
      <CategoryInputWrapper
        className={initialCategories?.length > 0 ? 'hasvalue' : ''}
        onClick={openModal}
      >
        <CategoryInput
          readOnly
          placeholder="운영할 업종을 모두 선택해 주세요."
          value={initialCategories
            .map((name) => {
              return fetchedCategories.find((category) => category.name === name)?.value
            })
            .join(', ')}
        />
        <CategoryInputRightElement>
          <ChevronDownIcon />
        </CategoryInputRightElement>
      </CategoryInputWrapper>
      <Modal
        headerText="업종 선택"
        isShown={isShown}
        isHideCloseButton={breakpoint.up('md')}
        hide={toggle}
        modalContent={content}
      />
    </CategoryBlock>
  )
}
type UseToastProps = {
  hiddenTime: number
}
const useToast = ({ hiddenTime }: UseToastProps) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null)
  const [isShowToast, setIsShowToast] = useState<boolean>(false)

  const showToast = () => {
    setIsShowToast(true)

    timerRef.current = setTimeout(() => setIsShowToast(false), hiddenTime)
  }

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return { isShowToast, showToast }
}
