import styled, { css } from 'styled-components'
import palette from 'lib/styles/palette'
import { SubTitle, Text } from 'components/common'
import { SubTitleM20Css } from 'components/common/SubTitle/SubTitle.styles'

export const CategoryBlock = styled.div`
  position: relative;
`

export const CategorySubText = styled(Text.M20)`
  max-width: 227px;
  text-align: center;
  margin: 0 auto;
`
export const CategoryCotentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`
export const CategoryCotentWrapper = styled.div`
  padding: 4px 32px 0;
`

export const CategoryInputWrapper = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    height: 44px;
    display: flex;
    border: 1px solid ${theme.color.gray[100]};
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;

    &.hasvalue {
      border-color: ${theme.color.gray[700]};
    }
  `}
`

export const CategoryInput = styled.input`
  ${({ theme }) => css`
    margin-left: 16px;
    width: 100%;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    color: ${theme.color.primary[300]};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${SubTitleM20Css};

    &::placeholder {
      color: ${theme.color.gray[300]};
      ${SubTitleM20Css};
    }
  `}
`

export const CategoryInputRightElement = styled.div`
  padding: 1rem;
`

export const CategoryToast = styled(SubTitle.M20)`
  ${({ theme }) => css`
    opacity: 0;
    transition: opacity 0.5s linear;

    &.show {
      opacity: 1;
    }

    pointer-events: none;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    bottom: 104px;
    transform: translate(-50%, 0);
    color: ${theme.color.gray[0]};
    background: rgba(51, 51, 51, 0.9);
    border-radius: 8px;
    padding: 8px 12px;
  `}
`

export const CategoryFooterButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  position: sticky;
  padding: 0 32px 32px;
  bottom: 0;
  left: 0;
  margin-top: auto;
  ${({ theme }) => css`
    background: ${theme.color.gray[0]};
  `}
`
