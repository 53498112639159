import styled, { css } from 'styled-components'

export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 32px;
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.color.gray[100]};
    background-color: ${theme.color.gray[0]};

    ${theme.breakpoint.mediaQuery.down('md')} {
      height: 44px;
      padding: 0;
      border-bottom: unset;
    }
  `}
`

export const HeaderWrapper = styled.div`
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 24px;
    height: 24px;
  }
`

export const HeaderButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
`

export const IconButton = styled.button`
  cursor: pointer;
  font-size: 0;
`
