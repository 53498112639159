import * as React from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { Heading, Text } from '@chakra-ui/react'

import { authAtom } from 'stores/atoms'
import { SearchIcon } from 'components/Icons/svg'
import { getSearchShops } from 'services/shop.service'
import ShopList from 'components/Shop/ShopList'
import { RegisterTemplate } from 'template/RegisterTemplate'

export const RegisterEmployeePage = () => {
  const authData = useRecoilValue(authAtom)
  const [searchedData, setSearchedData] = React.useState<object | null>(null)
  const { register, getValues, watch, handleSubmit } = useForm()

  const watchQuery = watch('query')

  React.useEffect(() => {
    !watchQuery && setSearchedData(null)
  }, [watchQuery])

  const onSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const { query } = formData
    try {
      const { data } = await getSearchShops(query)
      setSearchedData(data)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <RegisterTemplate backgroundColor="#ECF3FF">
      <SearchShopFormBlock>
        <Heading
          fontSize={{
            base: '16',
            md: '24px',
          }}
          textAlign="center"
          mb={42}
        >
          근무지 설정
        </Heading>

        <TitleWrapper>
          <Text color="main_blue" fontSize="lg" fontWeight={700}>
            {authData.name} 디자이너 님
          </Text>
          <Text color="main_blue" fontSize="lg" fontWeight={700}>
            입사하려는 샵 정보를 입력해 주세요.
          </Text>
        </TitleWrapper>

        <SearchShopInputBlock onSubmit={handleSubmit(onSubmit)}>
          <SearchInput
            type="text"
            autoComplete="off"
            placeholder="샵 이름 or 관리자 ID를 입력해 주세요."
            {...register('query')}
          />

          <InputRightElement>
            <button type="submit">
              <SearchIcon />
            </button>
          </InputRightElement>
        </SearchShopInputBlock>

        <ShopList data={searchedData} query={getValues('query')} />
      </SearchShopFormBlock>
    </RegisterTemplate>
  )
}

const TitleWrapper = styled.div`
  display: block;
  text-align: center;
  margin-top: 4.25rem;
  margin-bottom: 2.75rem;
`

const SearchShopFormBlock = styled.div`
  min-height: inherit;
`

const SearchShopInputBlock = styled.form`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`

const SearchInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  outline: none;
  border: none;
  height: 2.75rem;
  border-radius: 1.375rem;
  margin: 0;
  padding-inline-end: 2.5rem;
  padding-inline-start: 1rem;
  font-size: 14px;
  color: #20232b;
  transition: all 200ms;
  &:focus {
    box-shadow: 0 3px 10px 0 rgba(34, 126, 255, 0.2);
  }

  &::placeholder {
    color: #adb5bd;
  }
`

const InputRightElement = styled.div`
  width: 2.75rem;
  height: 2.75rem;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  z-index: 2;
`
