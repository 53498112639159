import styled, { css } from 'styled-components'

export const SelectWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 44px;

	${({ theme }) => css`
		background: ${theme.color.gray[0]};
		border: 1px solid ${theme.color.gray[100]};
		border-radius: 0.5rem;

		&.active {
			border-color: ${theme.color.gray[700]};

			& .select-display {
				color: ${theme.color.primary[300]};
			}
		}

		&.show {
			border: 1px solid ${theme.color.primary[300]};
			border-bottom: 1px solid transparent;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		&.disabled {
			border-color: ${theme.color.gray[50]};

			& .select-display {
				color: ${theme.color.gray[50]};
				cursor: default;
			}

			& .select-display-icon {
				path {
					fill: ${theme.color.gray[50]};
				}
			}
		}
	`}
`
