import { Flex, Text, TextField } from 'components/common'
import Button from 'components/common/Button'
import FormLabel from 'components/common/FormLabel'
import { forwardRef, useImperativeHandle, useState } from 'react'
import {
  AuthCertificationCodeTextField,
  AuthCertificationCodeTextFieldWrapper,
  AuthCertificationCodeTimer,
} from './AuthPhoneNumberForm.styles'
import { InputProps } from 'components/common/TextField/Input/Input.types'
import useTimer from 'hooks/useTimer'
import { numberRegex } from 'lib/helper'
import { getAuthPhoneNumberCode, sendAuthPhoneNumber } from 'services/auth.service'
import { COMMON_ERROR_TEXT } from 'constants/common/text'
import axios from 'axios'

const AuthPhoneNumberActionType = {
  /** 휴대폰 번호 기본 */
  DEFAULT: 'DEFAULT',
  /** 휴대폰 번호 입력 */
  SET_PHONE_NUMBER: 'SET_PHONE_NUMBER',
  /** 휴대폰 번호 인증 요청 (전송 버튼 클릭) */
  SEND_CERTIFICATION_CODE: 'SEND_CERTIFICATION_CODE',
  /** 휴대폰 번호 인증 요청 후 30초 경과 */
  ENABLE_RESEND_CERTIFICATION_CODE: 'ENABLE_RESEND_CERTIFICATION_CODE',
  /** 휴대폰 번호 인증 요청 후 5분 경과 (인증 시간 만료) */
  TIMEOUT_CERTIFICATION_CODE: 'TIMEOUT_CERTIFICATION_CODE',
  /** 인증 번호 입력 */
  SET_CERTIFICATION_CODE: 'SET_CERTIFICATION_CODE',
  /** 인증 번호 오류 */
  ERROR_CERTIFICATION_CODE: 'ERROR_CERTIFICATION_CODE',
  /** 인증 번호 확인 */
  CONFIRM_CERTIFICATION_CODE: 'CONFIRM_CERTIFICATION_CODE',
} as const

type TextFieldStateType = {
  status: InputProps['status']
  helperText: string
}

type ButtonStateType = {
  disabled: boolean
  displayText?: string
}

const MOBILE_PHONE_NUMBER_MIN_LENGTH = 10
const MOBILE_PHONE_NUMBER_MAX_LENGTH = 11
const COMMON_CERTIFICATION_CODE_MAX_LENGTH = 6

const INITIAL_PHONE_NUMBER_STATE = {
  status: 'DEFAULT',
  helperText: '',
} as const

const INITIAL_CERTIFICATION_CODE_STATE = {
  status: 'DEFAULT',
  helperText: '',
} as const

const INITIAL_SEND_CERTIFICATION_CODE_BUTTON_STATE = {
  disabled: true,
  displayText: '전송',
} as const

const INITIAL_CONFIRM_CERTIFICATION_CODE_BUTTON_STATE = {
  disabled: true,
} as const

/** 인증 번호 타이머 시간 (5분) */
const INITIAL_CERTIFICATION_CODE_TIMER_SECONDS = 300

type AuthPhoneNumberFormProps = {
  mobilePhoneNumber: string
  handleChangeMobilePhoneNumber: (mobilePhoneNumber: string) => void
  isCertificated: boolean
  handleChangeIsCertificated: (isCertificated: boolean) => void
}

export type ExportAuthPhoneNumberFormHandlers = {
  authPhoneNumberAction: ({ type }: { type: keyof typeof AuthPhoneNumberActionType }) => void
}

const AuthPhoneNumberForm = forwardRef<ExportAuthPhoneNumberFormHandlers, AuthPhoneNumberFormProps>(
  (
    {
      mobilePhoneNumber,
      handleChangeMobilePhoneNumber,
      isCertificated,
      handleChangeIsCertificated,
    },
    ref,
  ) => {
    const [certificationText, setCertificationText] = useState('')

    const [phonNumberState, setPhoneNumberState] = useState<TextFieldStateType>(
      INITIAL_PHONE_NUMBER_STATE,
    )

    const [certificationCodeState, setCertificationCodeState] = useState<TextFieldStateType>(
      INITIAL_CERTIFICATION_CODE_STATE,
    )

    const [isCertificationCodeFormVisible, setIsCertificationCodeFormVisible] = useState(false)

    const [sendCertificationCodeButtonState, setSendCertificationCodeButtonState] =
      useState<ButtonStateType>(INITIAL_SEND_CERTIFICATION_CODE_BUTTON_STATE)

    const [isCertificationTimerVisible, setIsCertificationTimerVisible] = useState(false)
    const [confirmCertificationCodeButtonState, setConfirmCertificationCodeButtonState] =
      useState<ButtonStateType>(INITIAL_CONFIRM_CERTIFICATION_CODE_BUTTON_STATE)

    const certificationCodeTimer = useTimer({
      initialTime: INITIAL_CERTIFICATION_CODE_TIMER_SECONDS,
      onTimeout() {
        authPhoneNumberAction({ type: AuthPhoneNumberActionType.TIMEOUT_CERTIFICATION_CODE })
      },
      onTick(elapsedTime) {
        if (elapsedTime > 30) {
          authPhoneNumberAction({
            type: AuthPhoneNumberActionType.ENABLE_RESEND_CERTIFICATION_CODE,
          })
        }
      },
    })

    function authPhoneNumberAction({ type }: { type: keyof typeof AuthPhoneNumberActionType }) {
      switch (type) {
        case AuthPhoneNumberActionType.DEFAULT:
          handleChangeIsCertificated(false)
          handleChangeMobilePhoneNumber('')
          setCertificationText('')
          setPhoneNumberState(INITIAL_PHONE_NUMBER_STATE)
          setSendCertificationCodeButtonState(INITIAL_SEND_CERTIFICATION_CODE_BUTTON_STATE)
          certificationCodeTimer.stop()
          return

        case AuthPhoneNumberActionType.SET_PHONE_NUMBER:
          handleChangeIsCertificated(false)
          setSendCertificationCodeButtonState({ disabled: false, displayText: '전송' })
          setConfirmCertificationCodeButtonState(INITIAL_CONFIRM_CERTIFICATION_CODE_BUTTON_STATE)
          setCertificationCodeState(INITIAL_CERTIFICATION_CODE_STATE)
          setIsCertificationTimerVisible(false)
          setCertificationText('')
          certificationCodeTimer.stop()
          return

        case AuthPhoneNumberActionType.SEND_CERTIFICATION_CODE:
          handleChangeIsCertificated(false)
          setIsCertificationCodeFormVisible(true)
          setPhoneNumberState(INITIAL_PHONE_NUMBER_STATE)
          setSendCertificationCodeButtonState({ disabled: true, displayText: '재전송' })
          setConfirmCertificationCodeButtonState(INITIAL_CONFIRM_CERTIFICATION_CODE_BUTTON_STATE)
          setCertificationCodeState(INITIAL_CERTIFICATION_CODE_STATE)
          setCertificationText('')
          setIsCertificationTimerVisible(true)
          certificationCodeTimer.reset()
          certificationCodeTimer.start()
          return

        case AuthPhoneNumberActionType.ENABLE_RESEND_CERTIFICATION_CODE:
          handleChangeIsCertificated(false)
          setSendCertificationCodeButtonState({ disabled: false, displayText: '재전송' })
          return

        case AuthPhoneNumberActionType.TIMEOUT_CERTIFICATION_CODE:
          handleChangeIsCertificated(false)
          setSendCertificationCodeButtonState({ disabled: false, displayText: '재전송' })
          setCertificationText('')
          setCertificationCodeState(INITIAL_CERTIFICATION_CODE_STATE)
          setConfirmCertificationCodeButtonState(INITIAL_CONFIRM_CERTIFICATION_CODE_BUTTON_STATE)
          setIsCertificationTimerVisible(false)
          certificationCodeTimer.stop()
          return

        case AuthPhoneNumberActionType.SET_CERTIFICATION_CODE:
          handleChangeIsCertificated(false)
          setConfirmCertificationCodeButtonState((prev) => ({ ...prev, disabled: false }))
          return

        case AuthPhoneNumberActionType.ERROR_CERTIFICATION_CODE:
          handleChangeIsCertificated(false)
          setCertificationCodeState((prev) => ({
            ...prev,
            status: 'ERROR',
            helperText: '인증번호를 확인해 주세요.',
          }))
          return

        case AuthPhoneNumberActionType.CONFIRM_CERTIFICATION_CODE:
          handleChangeIsCertificated(true)
          setPhoneNumberState((prev) => ({
            ...prev,
            status: 'DISABLED',
          }))
          setSendCertificationCodeButtonState({ disabled: false, displayText: '재인증' })
          setIsCertificationCodeFormVisible(false)
          certificationCodeTimer.stop()
          return
      }
    }

    useImperativeHandle(
      ref,
      () => ({
        authPhoneNumberAction,
      }),
      [],
    )

    const handleChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(numberRegex, '')
      if (value === '' || value.length < MOBILE_PHONE_NUMBER_MIN_LENGTH) {
        authPhoneNumberAction({ type: AuthPhoneNumberActionType.DEFAULT })
      } else {
        authPhoneNumberAction({ type: AuthPhoneNumberActionType.SET_PHONE_NUMBER })
      }
      handleChangeMobilePhoneNumber(value)
    }

    const handleChangeCertificationCode = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.replace(numberRegex, '')
      if (value === '') {
        setConfirmCertificationCodeButtonState((prev) => ({ ...prev, disabled: true }))
      } else {
        authPhoneNumberAction({ type: AuthPhoneNumberActionType.SET_CERTIFICATION_CODE })
      }
      setCertificationText(value)
    }

    const handleSendCertificationCode = async () => {
      try {
        await getAuthPhoneNumberCode({
          certificationType: 'SMS_CERT',
          mobilePhoneNumber,
        })
        authPhoneNumberAction({ type: AuthPhoneNumberActionType.SEND_CERTIFICATION_CODE })
      } catch (error) {
        alert(COMMON_ERROR_TEXT)
      }
    }

    const handleConfirmCertificationCode = async () => {
      try {
        const { status } = await sendAuthPhoneNumber({
          certificationText,
          certificationType: 'SMS_CERT',
          mobilePhoneNumber,
        })
        if (status === 204) {
          authPhoneNumberAction({ type: AuthPhoneNumberActionType.CONFIRM_CERTIFICATION_CODE })
        } else {
          throw new Error('error')
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          authPhoneNumberAction({ type: AuthPhoneNumberActionType.ERROR_CERTIFICATION_CODE })
        } else {
          alert(COMMON_ERROR_TEXT)
        }
      }
    }

    return (
      <>
        <FormLabel isRequired>휴대폰 인증</FormLabel>
        <Flex gap={16}>
          <TextField
            maxLength={MOBILE_PHONE_NUMBER_MAX_LENGTH}
            name="mobilePhoneNumber"
            placeholder="휴대폰 번호를 입력해 주세요."
            value={mobilePhoneNumber}
            onChange={handleChangePhoneNumber}
            status={phonNumberState.status}
          />
          <Button
            type="button"
            onClick={handleSendCertificationCode}
            disabled={sendCertificationCodeButtonState.disabled}
            variant="white"
            round
            border
            style={{ minWidth: 77, whiteSpace: 'nowrap' }}
          >
            {sendCertificationCodeButtonState.displayText}
          </Button>
        </Flex>
        {isCertificated && <Text.S className="color-gray-300">인증이 완료되었습니다.</Text.S>}
        {isCertificationCodeFormVisible && (
          <Flex gap={16}>
            <AuthCertificationCodeTextFieldWrapper>
              <AuthCertificationCodeTextField
                maxLength={COMMON_CERTIFICATION_CODE_MAX_LENGTH}
                placeholder="인증번호를 입력해 주세요."
                value={certificationText}
                onChange={handleChangeCertificationCode}
                status={certificationCodeState.status}
                helpText={certificationCodeState.helperText}
              />
              {isCertificationTimerVisible && (
                <AuthCertificationCodeTimer>
                  {formatTimer(certificationCodeTimer.timeLeft)}
                </AuthCertificationCodeTimer>
              )}
            </AuthCertificationCodeTextFieldWrapper>
            <Button
              type="button"
              disabled={confirmCertificationCodeButtonState.disabled}
              onClick={handleConfirmCertificationCode}
              variant="white"
              round
              border
              style={{ minWidth: 77 }}
            >
              인증
            </Button>
          </Flex>
        )}
      </>
    )
  },
)

AuthPhoneNumberForm.displayName = 'AuthPhoneNumberForm'

export default AuthPhoneNumberForm

const formatTimer = (time: number) => {
  const minutes = Math.floor(time / 60)
    .toString()
    .padStart(2, '0')
  const seconds = (time % 60).toString().padStart(2, '0')
  return `${minutes}:${seconds}`
}
