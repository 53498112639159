import { useState } from 'react'
import { shopNameRegex } from 'lib/helper'

type ShopName = {
  value: string
  status: 'SUCCESS01' | 'ERROR' | 'DEFAULT'
  helpText: string
}

const INITIAL_SHOP_NAME_STATE: ShopName = {
  value: '',
  status: 'DEFAULT',
  helpText: '',
}

export function useHandleShopName() {
  const [shopName, setShopName] = useState<ShopName>(INITIAL_SHOP_NAME_STATE)

  const validateShopName = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const isSuccess = shopNameRegex.test(value)

    if (value === '') {
      setShopName(INITIAL_SHOP_NAME_STATE)
    } else {
      setShopName({
        value,
        status: isSuccess ? 'SUCCESS01' : 'ERROR',
        // TODO: 정책 확인 요청 답변 받으면 수정 필요
        helpText: isSuccess ? '사용할 수 있는 샵 이름입니다.' : '사용할 수 없는 문자가 있습니다.',
      })
    }
  }

  return {
    shopName,
    validateShopName,
  }
}
