import * as React from 'react'
import { RadioChangeEvent } from 'antd'
import { AxiosError } from 'axios'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, useWatch } from 'react-hook-form'
import 'antd/dist/antd.min.css'
import 'antd/es/date-picker/style/index'

import { DateChecks } from 'components/DateChecks'
import Postcode from 'components/Postcode'

import { Modal } from 'components/Modal'
import { useModal } from 'components/Modal/hooks/useModal'

import { authAtom } from 'stores/atoms'
import { ownerAtom } from 'stores/atoms/auth'

import { registerShop } from 'services/shop.service'

import { queryPramsAtom } from 'stores/atoms/users'

import { Steps, Step } from 'components/Steps'
import { useSteps } from 'components/Steps/hooks/useSteps'
import { Category } from 'components/CategoriesSelects/Category'
import { useCategorySelect } from 'components/CategoriesSelects/hooks/useCategorySelect'
import { Flex, TextField, Title } from 'components/common'
import { useHandleShopName } from 'hooks/useHandleShopName'
import { useHandleRecommendId } from 'hooks/useHandleRecommendId'
import { COMMON_ERROR_TEXT } from 'constants/common/text'
import FullContent from 'template/FullContent'
import {
  AuthRegisterOwnerBusinessStatusInfoBullet,
  AuthRegisterOwnerBusinessStatusInfoItem,
  AuthRegisterOwnerBusinessStatusInfoText,
  AuthRegisterOwnerBusinessStatusInfoWrapper,
  AuthRegisterOwnerContentBox,
  AuthRegisterOwnerContentWrapper,
  AuthRegisterOwnerForm,
  AuthRegisterOwnerFormFooterButtonWrapper,
  AuthRegisterOwnerFormItem,
  AuthRegisterOwnerFormWrapper,
  AuthRegisterOwnerRadio,
  AuthRegisterOwnerRadioBlock,
} from './AuthRegisterOwner.styles'
import FormLabel from 'components/common/FormLabel'
import Breakpoint from 'components/common/Breakpoint'
import MobileTopbar from 'components/common/MobileTopbar'
import Button from 'components/common/Button'
import Spacing from 'components/common/Spacing'
import BusinessTimeForm from 'components/BusinessTimeForm'
import { BusinessDaysOfWeekItemType, RegularDayOfWeekType } from 'types/RegisterOwner'
import { transformBusinessDaysOfWeekListFromRegularDayOfWeek } from 'utils/RegisterOwner'
import {
  INITIAL_BUSINESS_DAYS_OF_WEEK,
  INITIAL_REGULAR_BUSINESS_DAY_OF_WEEK,
} from 'constants/RegisterOwner'
import useDidMountEffect from 'hooks/useDidMountEffect'
import useBeforeUnload from 'hooks/useBeforeUnload'

type AuthRegisterOwnerFormType = {
  categories: string[]
  addressBasic: string
  zipCode: number
  addressDetail: string
}
export const RegisterOwnerPage: React.FC = () => {
  const authData = useRecoilValue(authAtom)
  const queryParamsAtom = useRecoilValue(queryPramsAtom)
  const formRef = React.useRef<HTMLFormElement | null>(null)

  const setOwnerAuthData = useSetRecoilState(ownerAtom)
  const { isShown, toggle } = useModal()
  const [timeInterval, setTimeInterval] = React.useState('00:10')
  const { fetchedCategories } = useCategorySelect()
  const [isLoading, setIsLoading] = React.useState(false)
  const [regularBusinessDayOfWeek, setRegularBusinessDayOfWeek] =
    React.useState<RegularDayOfWeekType>(INITIAL_REGULAR_BUSINESS_DAY_OF_WEEK)

  const [businessDaysOfWeek, setBusinessDaysOfWeek] = React.useState<BusinessDaysOfWeekItemType[]>(
    INITIAL_BUSINESS_DAYS_OF_WEEK,
  )

  const {
    setValue,
    control,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<AuthRegisterOwnerFormType>({
    mode: 'all',
    defaultValues: {
      categories: [],
      addressBasic: '',
      zipCode: 0,
    },
  })

  const { addressBasic, addressDetail, categories } = useWatch<AuthRegisterOwnerFormType>({
    control,
  })

  const { shopName, validateShopName } = useHandleShopName()
  const { recommendId, onChangeRecommendId } = useHandleRecommendId()

  const { nextStep, prevStep, activeStep } = useSteps({ initialStep: 0 })

  const setBeforeUnload = useBeforeUnload({ useBeforeUnload: false })

  useDidMountEffect(() => {
    setBeforeUnload(true)
  }, [shopName.value, categories, addressBasic, addressDetail, recommendId.value])

  const isBusinessDayChecked = () => {
    return Object.values(regularBusinessDayOfWeek).filter(Boolean).length > 0
  }

  const onChangeTimeInterval = (e: RadioChangeEvent) => {
    setTimeInterval(e.target.value)
  }

  const onSubmit = () => {
    if (!categories!.length) {
      return
    }
    return nextStep()
  }

  const onSubmitNextStep = async (data: any) => {
    if (!authData.empNo) {
      return
    }
    const payload = {
      ...data,
      name: shopName.value,
      recommendId:
        recommendId.status === 'SUCCESS01' && !recommendId.hasNoneId ? recommendId.value : '',
      regularBusinessDayOfWeek,
      ownerEmpNo: authData.empNo,
      bookingTimeUnit: timeInterval,
      businessDaysOfWeek,
    }

    setIsLoading(true)

    try {
      const {
        data: {
          data: { freeTrialEndDate, shopNo, token },
          errorResponse,
        },
      } = await registerShop(payload)
      if (errorResponse.status !== 200) {
        if (errorResponse.status === 500) {
          alert(COMMON_ERROR_TEXT)
        } else {
          alert(errorResponse.message)
          return
        }
      }
      setBeforeUnload(false)
      setOwnerAuthData({ freeTrialEndDate, shopNo, token })
      onSumitFormAction(token)
    } catch (error: unknown) {
      const err = error as AxiosError
      if (err.response?.status === 500) {
        alert(COMMON_ERROR_TEXT)
      } else {
        alert(err.response?.data.message)
      }
      prevStep()
    } finally {
      setIsLoading(false)
    }
  }

  const onSumitFormAction = (token: string) => {
    let newInput = document.createElement('input')
    newInput.setAttribute('type', 'hidden')
    newInput.setAttribute('name', 'token')
    newInput.setAttribute('value', token)

    if (formRef.current) {
      formRef.current.method = 'post'
      formRef.current.action = queryParamsAtom.returnUrl
      formRef.current.appendChild(newInput)
      formRef.current.submit()
      return
    }
  }

  const content = (
    <Postcode
      toggle={toggle}
      handleChangeAddress={(address) => {
        setValue('addressBasic', address.addressBasic)
        setValue('zipCode', address.zipCode)
      }}
    />
  )

  return (
    <FullContent>
      <FullContent.Header>
        <Breakpoint.Up size="md">
          <FullContent.HeaderTitle icon="systemShop" title="샵 등록하기" />
        </Breakpoint.Up>
        <Breakpoint.Down size="md">
          <MobileTopbar
            leftContent={
              activeStep === 1 && <MobileTopbar.IconButton icon="systemArrow" onClick={prevStep} />
            }
            centerContent={<Title.L>샵 등록하기</Title.L>}
          />
        </Breakpoint.Down>
      </FullContent.Header>
      <FullContent.Content>
        <AuthRegisterOwnerContentWrapper>
          <AuthRegisterOwnerContentBox>
            <Steps activeStep={activeStep}>
              <Step>
                <AuthRegisterOwnerForm onSubmit={handleSubmit(onSubmit)}>
                  <AuthRegisterOwnerFormWrapper>
                    <AuthRegisterOwnerFormItem>
                      <FormLabel isRequired htmlFor="name">
                        샵 이름
                      </FormLabel>
                      <TextField
                        onInvalid={(e) => e.preventDefault()}
                        placeholder="샵 이름을 입력해 주세요."
                        onChange={validateShopName}
                        value={shopName.value}
                        helpText={shopName.helpText}
                        status={shopName.status}
                        maxLength={50}
                      />
                    </AuthRegisterOwnerFormItem>
                    <AuthRegisterOwnerFormItem>
                      <FormLabel isRequired>업종 선택</FormLabel>
                      <Category
                        fetchedCategories={fetchedCategories}
                        initialCategories={categories!}
                        handleUpdateCategories={(selectedCategories) => {
                          setValue('categories', selectedCategories)
                        }}
                      />
                    </AuthRegisterOwnerFormItem>
                    <AuthRegisterOwnerFormItem>
                      <FormLabel isRequired>샵 주소</FormLabel>
                      <Flex flexDirection="column" gap={8}>
                        <Flex alignItems="flex-end" onClick={toggle} width="100%" gap={16}>
                          <TextField
                            type="text"
                            autoComplete="off"
                            readOnly
                            placeholder="샵 주소를 입력해 주세요."
                            {...register('addressBasic', {
                              required: true,
                            })}
                          />
                          <Button
                            style={{
                              whiteSpace: 'nowrap',
                            }}
                            type="button"
                            round
                            variant="white"
                            border
                            onClick={toggle}
                          >
                            주소검색
                          </Button>
                        </Flex>
                        <TextField
                          type="text"
                          autoComplete="off"
                          placeholder="(필수) 샵 상세 주소를 입력해 주세요."
                          {...register('addressDetail', {
                            required: true,
                          })}
                        />
                      </Flex>
                    </AuthRegisterOwnerFormItem>
                    <AuthRegisterOwnerFormItem>
                      <FormLabel>추천인</FormLabel>
                      <TextField
                        placeholder="추천인 아이디를 입력해 주세요."
                        helpText={recommendId.helpText}
                        status={recommendId.status}
                        value={recommendId.value}
                        onChange={onChangeRecommendId}
                      />
                    </AuthRegisterOwnerFormItem>
                  </AuthRegisterOwnerFormWrapper>
                  <AuthRegisterOwnerFormFooterButtonWrapper>
                    <Button
                      variant="blue"
                      stretch
                      size="large"
                      type="submit"
                      disabled={!(shopName.status === 'SUCCESS01' && isValid)}
                    >
                      다음
                    </Button>
                  </AuthRegisterOwnerFormFooterButtonWrapper>
                </AuthRegisterOwnerForm>

                <Modal
                  headerText="샵 등록하기"
                  isShown={isShown}
                  hide={toggle}
                  modalContent={content}
                />
              </Step>

              <Step>
                <AuthRegisterOwnerForm onSubmit={handleSubmit(onSubmitNextStep)} ref={formRef}>
                  <AuthRegisterOwnerFormWrapper>
                    <AuthRegisterOwnerFormItem>
                      <Flex justifyContent="space-between">
                        <FormLabel isRequired>영업일</FormLabel>
                        <AuthRegisterOwnerBusinessStatusInfoWrapper>
                          {[
                            {
                              kind: 'business-day',
                              text: '영업',
                            },
                            {
                              kind: 'regular-holiday',
                              text: '정기휴무',
                            },
                          ].map(({ kind, text }) => (
                            <AuthRegisterOwnerBusinessStatusInfoItem key={kind}>
                              <AuthRegisterOwnerBusinessStatusInfoBullet className={kind} />
                              <AuthRegisterOwnerBusinessStatusInfoText>
                                {text}
                              </AuthRegisterOwnerBusinessStatusInfoText>
                            </AuthRegisterOwnerBusinessStatusInfoItem>
                          ))}
                        </AuthRegisterOwnerBusinessStatusInfoWrapper>
                      </Flex>
                      <DateChecks
                        handleChangeRegularBusinessDayOfWeek={(regularBusinessDayOfWeek) => {
                          setRegularBusinessDayOfWeek(regularBusinessDayOfWeek)
                        }}
                      />
                    </AuthRegisterOwnerFormItem>
                    <AuthRegisterOwnerFormItem>
                      <FormLabel isRequired>영업 시간</FormLabel>
                      <Spacing size="12" />
                      <BusinessTimeForm
                        activatedDaysOfWeek={transformBusinessDaysOfWeekListFromRegularDayOfWeek(
                          regularBusinessDayOfWeek,
                        )}
                        businessDaysOfWeek={businessDaysOfWeek}
                        handleChangeBusinessDaysOfWeek={(businessDaysOfWeek) => {
                          setBusinessDaysOfWeek(businessDaysOfWeek)
                        }}
                      />
                    </AuthRegisterOwnerFormItem>
                    <AuthRegisterOwnerFormItem>
                      <FormLabel isRequired>예약시간 간격</FormLabel>
                      <AuthRegisterOwnerRadioBlock
                        onChange={onChangeTimeInterval}
                        value={timeInterval}
                        size="large"
                      >
                        <AuthRegisterOwnerRadio value="00:10">10분</AuthRegisterOwnerRadio>
                        <AuthRegisterOwnerRadio value="00:30">30분</AuthRegisterOwnerRadio>
                      </AuthRegisterOwnerRadioBlock>
                    </AuthRegisterOwnerFormItem>
                  </AuthRegisterOwnerFormWrapper>
                  <Spacing size="48" />
                  <AuthRegisterOwnerFormFooterButtonWrapper>
                    <Button
                      onClick={prevStep}
                      variant="white"
                      border
                      stretch
                      type="button"
                      size="large"
                    >
                      이전
                    </Button>
                    <Button
                      variant="blue"
                      isLoading={isLoading}
                      disabled={!isBusinessDayChecked()}
                      stretch
                      size="large"
                      type="submit"
                    >
                      샵 생성 완료
                    </Button>
                  </AuthRegisterOwnerFormFooterButtonWrapper>
                </AuthRegisterOwnerForm>
              </Step>
            </Steps>
          </AuthRegisterOwnerContentBox>
        </AuthRegisterOwnerContentWrapper>
      </FullContent.Content>
    </FullContent>
  )
}
