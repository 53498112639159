import { useState, useEffect, useCallback } from 'react'

type UseTimerProps = {
  /** 타이머 초기 시간 */
  initialTime: number
  /** 타이머 종료 시 실행할 함수 */
  onTimeout?: () => void
  /** 매 초마다 실행될 함수 (elapsedTime: 경과 시간, remainingTime: 남은 시간)
   */
  onTick?: (elapsedTime: number, remainingTime: number) => void
}

type UseTimerReturn = {
  /** 남은 시간 */
  timeLeft: number
  /** 경과된 시간 */
  elapsedTime: number
  /** 타이머 실행 여부 */
  isRunning: boolean
  /** 타이머 시작 (선택적으로 초기 시간을 지정 가능) */
  start: (time?: number) => void
  /** 타이머 정지 */
  stop: () => void
  /** 타이머 초기화 */
  reset: () => void
}

const useTimer = ({
  initialTime,
  onTimeout = () => {},
  onTick = () => {},
}: UseTimerProps): UseTimerReturn => {
  const [timeLeft, setTimeLeft] = useState<number>(initialTime)
  const [isRunning, setIsRunning] = useState<boolean>(false)
  const [elapsedTime, setElapsedTime] = useState<number>(0)

  useEffect(() => {
    if (isRunning && timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev - 1 === 0) {
            setIsRunning(false) // 타이머가 끝날 때 실행
            onTimeout() // 타이머 종료 시 실행
          }
          const newTime = prev - 1
          const newElapsedTime = initialTime - newTime
          setElapsedTime(newElapsedTime)
          onTick(newElapsedTime, newTime) // 경과 시간과 남은 시간 전달
          return newTime
        })
      }, 1000)

      return () => clearInterval(timerId)
    }
  }, [isRunning, timeLeft, initialTime, onTimeout, onTick])

  const start = useCallback(
    (time: number = initialTime) => {
      setTimeLeft(time)
      setElapsedTime(0)
      setIsRunning(true)
    },
    [initialTime],
  )

  const stop = useCallback(() => {
    setIsRunning(false)
  }, [])

  const reset = useCallback(() => {
    setTimeLeft(initialTime)
    setElapsedTime(0)
    setIsRunning(false)
  }, [initialTime])

  return { timeLeft, elapsedTime, isRunning, start, stop, reset }
}

export default useTimer
