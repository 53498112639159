import { useEffect, useRef } from 'react'
import { useBlocker } from 'react-router-dom'

const BEFORE_UNLOAD_TITLE = '변경사항이 저장되지 않을 수 있습니다.'

type Options = {
  useBeforeUnload?: boolean
  confirmText?: string
  callback?: () => void
}

const defaultOptions: Options = {
  useBeforeUnload: true,
  confirmText: BEFORE_UNLOAD_TITLE,
}

export default function useBeforeUnload(options: Options = defaultOptions) {
  options = {
    ...defaultOptions,
    ...options,
  }

  const useBeforeUnloadRef = useRef(options.useBeforeUnload)

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !!useBeforeUnloadRef.current && currentLocation.pathname !== nextLocation.pathname,
  )

  useEffect(() => {
    if (blocker.state === 'blocked' && window.confirm('변경사항이 저장되지 않을 수 있습니다.')) {
      blocker.proceed()
    }
  }, [blocker])

  useEffect(() => {
    const beforeunloadCallback = (e: BeforeUnloadEvent) => {
      if (!useBeforeUnloadRef.current) return
      e.returnValue = options.confirmText
      return options.confirmText
    }

    window.addEventListener('beforeunload', beforeunloadCallback)

    return () => {
      window.removeEventListener('beforeunload', beforeunloadCallback)
    }
  }, [useBeforeUnloadRef.current, options])

  return (value: boolean) => (useBeforeUnloadRef.current = value)
}
