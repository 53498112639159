import { extendTheme } from '@chakra-ui/react'
import palette from 'lib/styles/palette'
import { Button } from './Button'
import { DefaultTheme } from 'styled-components'

const breakpointSize: DefaultTheme['breakpoint']['size'] = {
  md: 768,
}
export const commonTheme: DefaultTheme = {
  color: {
    primary: {
      100: '#ECF3FF',
      150: '#BDD8FF',
      200: '#90BEFF',
      300: '#227EFF',
      400: '#0D51FF',
      500: '#0534FF',
    },
    secondary: {
      100: '#F0EFFF',
      150: '#D3CEFF',
      200: '#B6ACFF',
      300: '#6D5AFF',
      400: '#4E3DFF',
      500: '#3829FF',
    },
    tertiary: {
      100: '#EAFAFA',
      150: '#C1F0EF',
      200: '#98E6E4',
      300: '#31CDC9',
      400: '#15B1AB',
      500: '#099992',
    },
    red: {
      100: '#FEECEC',
      150: '#FBC5C5',
      200: '#F79E9E',
      300: '#F03E3E',
      400: '#E20F0F',
      500: '#D50404',
    },
    yellow: {
      100: '#FFFAEF',
      150: '#FFEFCE',
      200: '#FFE4AD',
      300: '#FFC95C',
      400: '#FF9E21',
      500: '#FF7D0C',
    },
    gray: {
      0: '#FFFFFF',
      50: '#EFF0F0',
      100: '#DFE0E1',
      200: '#BFC0C2',
      300: '#9FA1A4',
      400: '#808186',
      500: '#606268',
      600: '#404249',
      700: '#20232B',
    },
    bg: {
      100: '#FFFFFF',
      200: '#F9F9FB',
      300: '#ECF3FF',
    },
    shadow: {
      1: '#227EFF1A',
      2: '#00000033',
    },
    dim: '#0000004D',
  },
  fontSize: {
    xxl: '24px',
    xl: '20px',
    l: '16px',
    m: '14px',
    s: '12px',
    xs: '11px',
    accent: '32px',
  },
  fontFamily: 'Pretendard, Pretendard_Fallback',
  fontWeight: {
    bold: 700,
    medium: 500,
    regular: 400,
  },
  lineHeight: {
    xxl: '36px',
    xl: '32px',
    l: '24px',
    m: '20px',
    s: '18px',
    xs: '16px',
    accent: '48px',
  },
  spacing: [
    '4',
    '8',
    '12',
    '16',
    '20',
    '24',
    '28',
    '32',
    '36',
    '40',
    '44',
    '48',
    '52',
    '60',
    '100',
  ],
  breakpoint: {
    size: breakpointSize,
    mediaQuery: {
      up: (size) => `@media screen and (min-width: ${breakpointSize[size] + 1}px)`,
      down: (size) => `@media screen and (max-width: ${breakpointSize[size]}px)`,
    },
  },
}
export const commonChakraTheme = extendTheme({
  components: {
    Button,
  },

  fonts: {
    heading: 'Pretendard, Pretendard_Fallback',
    body: 'Pretendard, Pretendard_Fallback',
  },

  colors: palette,

  ...commonTheme,
})
