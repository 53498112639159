import { DefaultTheme, useTheme } from 'styled-components'
import { useMediaQuery } from './useMediaQuery'

const useBreakpoints = () => {
  const theme = useTheme()
  const matche = useMediaQuery
  return {
    up: (size: keyof DefaultTheme['breakpoint']['size']) => {
      return matche(`(min-width: ${theme.breakpoint.size[size] + 1}px)`)
    },
    down: (size: keyof DefaultTheme['breakpoint']['size']) => {
      return matche(`(max-width: ${theme.breakpoint.size[size]}px)`)
    },
  }
}

export default useBreakpoints
