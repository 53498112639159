import Content from './Content'
import { FullContentWrapper } from './FullContent.styles'
import Header from './Header'
import { HeaderButtonWrapper } from './Header/Header.styles'
import HeaderTitle from './HeaderTitle'

type FullContentProps = {
  children: React.ReactNode
}

const FullContent = ({ children }: FullContentProps) => {
  return <FullContentWrapper flexDirection="column">{children}</FullContentWrapper>
}

export default Object.assign(FullContent, {
  Header,
  HeaderTitle,
  HeaderButtonWrapper,
  Content,
})
