import { userIdRegex } from 'lib/helper'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { getUsers } from 'services/user.service'
import { authAtom } from 'stores/atoms'

type RecommendId = {
  status: 'SUCCESS01' | 'ERROR' | 'DEFAULT'
  helpText: string
  hasNoneId?: boolean
}

const INITIAL_RECOMMEND_ID_STATE: RecommendId = {
  status: 'DEFAULT',
  helpText: '추천인 입력 시 두 분 모두 혜택을 드립니다.',
}

export function useHandleRecommendId() {
  const [recommendId, setRecommendId] = useState<RecommendId>(INITIAL_RECOMMEND_ID_STATE)
  const [value, setValue] = useState('')
  const authData = useRecoilValue(authAtom)

  useEffect(() => {
    if (value === '' || isOnlyWhitespace(value)) {
      setRecommendId(INITIAL_RECOMMEND_ID_STATE)
      return
    } else if (value === authData.id) {
      setRecommendId({
        status: 'SUCCESS01',
        helpText: '본인 아이디에는 혜택이 적용되지 않습니다.',
      })
      return
    } else if (!userIdRegex.test(value)) {
      setRecommendId({ status: 'SUCCESS01', helpText: '없는 아이디입니다.', hasNoneId: true })
      return
    }

    const timer = setTimeout(async () => {
      try {
        const response = await getUsers(value)

        if (response.data.data.empty) {
          throw new Error('없는 아이디입니다.')
        }

        setRecommendId({
          status: 'SUCCESS01',
          helpText: `추천 가능한 아이디입니다.`,
        })
      } catch (err) {
        setRecommendId({ status: 'SUCCESS01', helpText: '없는 아이디입니다.', hasNoneId: true })
      }
    }, 100)

    return () => {
      clearTimeout(timer)
    }
  }, [value])

  const onChangeValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value)
  }

  return {
    recommendId: {
      value,
      status: recommendId.status,
      helpText: recommendId.helpText,
      hasNoneId: !!recommendId?.hasNoneId,
    },
    onChangeRecommendId: onChangeValue,
  }
}

function isOnlyWhitespace(value: string) {
  return value.trim() === ''
}
