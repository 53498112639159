import { Title } from 'components/common'
import media from 'lib/styles/media'
import styled, { css } from 'styled-components'

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: inherit;
  outline: 0;
`
const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`
const ModalBlock = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  width: 500px;
  border-radius: 0.5rem;
  box-shadow: 20px 20px 20px 0 rgba(113, 140, 150, 0.3);

  ${media.mobile} {
    width: 100%;
    height: 100vh;
    flex: 1;
    box-sizing: border-box;
    border-radius: 0;
  }
`
const ModalHeader = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    padding: 16px;
    position: relative;
    height: 56px;
    box-sizing: border-box;
    vertical-align: middle;
    margin-left: auto;

    ${theme.breakpoint.mediaQuery.down('md')} {
      padding: 10px;
    }
  `}
`
export const HeaderTitle = styled(Title.L)`
  text-align: center;
`

const HeaderText = styled.h2`
  font-size: 1.25rem;
  font-weight: 700;
`

const CloseButton = styled.button`
  border: none;
  border-radius: 0.5rem;
  background: none;
  right: 16px;
  top: 16px;
  position: absolute;
  display: flex;
  :hover {
    cursor: pointer;
  }

  ${({ theme }) => css`
    ${theme.breakpoint.mediaQuery.down('md')} {
      right: 10px;
      top: 10px;
    }
  `}
`

const ModalContent = styled.div`
  overflow-x: hidden;
  border-radius: 0.5rem;

  ${media.mobile} {
    width: 100%;
    height: calc(100% - 56px);
    box-sizing: border-box;
  }
`

export { ModalWrapper, Backdrop, ModalBlock, ModalHeader, HeaderText, CloseButton, ModalContent }
