import styled, { DefaultTheme, css } from 'styled-components'

export type SpacingSizes = DefaultTheme['spacing'][number]

type SpacingDirections = 'vertical' | 'horizontal'

type Props = {
	direction?: SpacingDirections
	size: SpacingSizes
}

const Spacing = styled.div<Props>`
	${({ direction = 'vertical', size }) => {
		if (direction === 'vertical') {
			return css`
				height: ${size}px;
				width: 100%;
			`
		} else {
			return css`
				width: ${size}px;
				height: 100%;
			`
		}
	}}
`
export default Spacing
