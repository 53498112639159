import findElement from 'utils/common/findElement'
import { useEffect } from 'react'

type UITarget<T extends HTMLElement> = React.RefObject<T> | HTMLElement | string
type UseClickOutOfAreaParams = {
  callback: () => void
  use?: boolean
  target: UITarget<HTMLElement> | UITarget<HTMLElement>[]
}

function useClickOutOfArea(
  { target, callback, use = true }: UseClickOutOfAreaParams,
  deps: React.DependencyList = [],
) {
  useEffect(() => {
    const _targets = (Array.isArray(target) ? target : [target]).map(
      (target) => target && findElement(target),
    )

    if (!use || !_targets.every(Boolean)) return

    document.addEventListener('mousedown', handleClickOutOfArea)

    return () => {
      document.removeEventListener('mousedown', handleClickOutOfArea)
    }

    function handleClickOutOfArea(e: MouseEvent) {
      if (_targets.every((_target) => _target && !_target.contains(e.target as Node))) {
        callback()
      }
    }
  }, [callback, ...deps])
}

export default useClickOutOfArea
