import { Text, TextField } from 'components/common'
import styled, { css } from 'styled-components'

export const AuthCertificationCodeTextFieldWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  position: relative;
`
export const AuthCertificationCodeTextField = styled(TextField)``

export const AuthCertificationCodeTimer = styled(Text.M20)`
  ${({ theme }) => css`
    position: absolute;
    right: 0;
    top: 12px;
    color: ${theme.color.red[300]};
  `}
`
