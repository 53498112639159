import { Radio } from 'antd'
import { Text } from 'components/common'
import styled from 'styled-components'
import { css } from 'styled-components'

export const AuthRegisterOwnerContentWrapper = styled.div`
  width: 100%;
  padding: 88px 0;

  ${({ theme }) => css`
    ${theme.breakpoint.mediaQuery.down('md')} {
      padding: 0;
    }
  `}
`

export const AuthRegisterOwnerContentBox = styled.div`
  ${({ theme }) => css`
    max-width: 500px;
    width: 100%;
    border-radius: 8px;
    background: ${theme.color.gray[0]};
    margin: 0 auto;
    padding: 32px;
    box-shadow: 0 3px 10px 0px ${theme.color.shadow[1]};

    ${theme.breakpoint.mediaQuery.down('md')} {
      max-width: unset;
      padding: 20px 20px 88px 20px;
      box-shadow: unset;
    }
  `}
`

export const AuthRegisterOwnerForm = styled.form`
  width: 100%;
`

export const AuthRegisterOwnerRadioBlock = styled(Radio.Group)`
  width: 100%;
  margin-top: 0.625rem;
  display: flex;
  align-items: center;
`

export const AuthRegisterOwnerRadio = styled(Radio)`
  width: 50%;
  display: flex;
  align-items: flex-end;

  height: 100%;

  ${({ theme }) => css`
    .ant-radio-inner {
      width: 28px;
      height: 28px;
      border-color: ${theme.color.gray[300]};
      transition: all 500ms;
      outline: none;

      &::after {
        width: 32px;
        height: 32px;
        top: 5px;
        left: 5px;
        background-color: ${theme.color.primary[300]};
      }

      &:active {
        box-shadow: none;
      }

      &:hover {
        border-color: ${theme.color.primary[300]};
      }
    }
  `}
`

export const AuthRegisterOwnerFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 48px;

  ${({ theme }) => css`
    ${theme.breakpoint.mediaQuery.down('md')} {
      gap: 32px;
    }
  `}
`

export const AuthRegisterOwnerFormItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const AuthRegisterOwnerBusinessStatusInfoWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`

export const AuthRegisterOwnerBusinessStatusInfoItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

export const AuthRegisterOwnerBusinessStatusInfoText = styled(Text.S)`
  ${({ theme }) => css`
    color: ${theme.color.gray[600]};
  `}
`

export const AuthRegisterOwnerBusinessStatusInfoBullet = styled.span`
  ${({ theme }) => css`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    &.business-day {
      background: ${theme.color.primary[300]};
    }

    &.regular-holiday {
      background: ${theme.color.bg[200]};
    }
  `}
`

export const AuthRegisterOwnerFormFooterButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  ${({ theme }) => css`
    ${theme.breakpoint.mediaQuery.down('md')} {
      background: ${theme.color.gray[0]};
      position: fixed;
      padding: 0 20px 32px 20px;
      bottom: 0;
      left: 0;
    }
  `}
`
