import { SubTitle } from 'components/common'
import { TitleM24Css } from 'components/common/Title/Title.styles'
import styled, { css } from 'styled-components'

export const BusinessTimeFormWrapper = styled.div``

export const BusinessTimeFormBusinessTypeTabWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  ${({ theme }) => css`
    background: ${theme.color.bg[200]};
    padding: 4px;
    border-radius: 8px;
  `}
`

export const BusinessTimeFormBusinessTypeTabItem = styled(SubTitle.M24)`
  ${({ theme }) => css`
    width: 50%;
    padding: 8px 0;
    text-align: center;
    border-radius: 4px;
    background: ${theme.color.bg[200]};
    color: ${theme.color.gray[500]};

    &.active {
      ${TitleM24Css};
      background: ${theme.color.bg[100]};
      color: ${theme.color.gray[700]};
    }
  `}
`

export const BusinessTimeFormBusinessTypeDaySelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  ${({ theme }) => css`
    &.disabled {
      color: ${theme.color.gray[50]};
    }
  `}
`
export const BusinessTimeFormSelectDaysTitle = styled(SubTitle.L)`
  width: 42px;
  white-space: nowrap;
  flex-shrink: 0;
`
export const BusinessTimeFormSelectInfoTitle = styled(SubTitle.M24)`
  width: 50%;
  ${({ theme }) => css`
    color: ${theme.color.gray[400]};
  `}
`
