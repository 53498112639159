import styled, { css } from 'styled-components'

import { Flex } from 'components/common'

export type ContentWrapperProps = {
  fullHeight: boolean
}
export const ContentWrapper = styled(Flex)<ContentWrapperProps>`
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  ${({ fullHeight }) => {
    if (fullHeight) {
      return css`
        flex: 1;
      `
    }
  }}
`

export const Spacer = styled.div`
  height: 56px;
`
