import { useState } from 'react'
import { AxiosError } from 'axios'
import { userIdRegex } from 'lib/helper'
import { checkId } from 'services/auth.service'
import useDidMountEffect from './useDidMountEffect'

type UserId = {
  status: 'SUCCESS01' | 'ERROR' | 'DEFAULT'
  helpText: string
}

const INITIAL_USER_ID_STATE: UserId = {
  status: 'DEFAULT',
  helpText: '영 소문자, 숫자를 사용해 4~20자 이내로 입력해 주세요.',
}

export function useHandleUserId() {
  const [userId, setUserId] = useState<UserId>(INITIAL_USER_ID_STATE)
  const [value, setValue] = useState('')

  const onCheckId = async () => {
    try {
      const {
        data: {
          data: { isDuplicated },
        },
      } = await checkId(value)

      if (isDuplicated) {
        setUserId({
          helpText: '이미 사용 중인 아이디입니다.',
          status: 'ERROR',
        })
      } else {
        setUserId({
          helpText: '사용할 수 있는 아이디입니다.',
          status: 'SUCCESS01',
        })
      }
    } catch (err: unknown) {
      const error = err as AxiosError
      alert(error.response?.data.message)
    }
  }

  useDidMountEffect(() => {
    if (value === '') {
      setUserId(INITIAL_USER_ID_STATE)
      return
    } else if (!userIdRegex.test(value)) {
      setUserId({
        status: 'ERROR',
        helpText: '영 소문자, 숫자를 사용해 4~20자 이내로 입력해 주세요.',
      })
      return
    }

    const timer = setTimeout(() => {
      return onCheckId()
    }, 100)

    return () => {
      clearTimeout(timer)
    }
  }, [value])

  const onChangeValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setValue(value)
  }

  return {
    userId: { value, status: userId.status, helpText: userId.helpText },
    onChangeUserId: onChangeValue,
  }
}
