import styled, { css } from 'styled-components'
import SubTitle from '../SubTitle'
import { applyShorteningWords } from 'utils/common/styles'

export const StyledOption = styled.button`
  padding: 12px 16px 12px 16px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  color: ${({ theme }) => theme.color.gray[700]};
  ${({ theme }) => css`
    &.selected,
    &:hover,
    &:focus {
      background-color: ${theme.color.primary['100']};
    }
  `}
`

export const StyledOptionText = styled(SubTitle.M20).attrs({
  as: 'p',
})`
  width: 100%;
  ${applyShorteningWords(2)}
`
