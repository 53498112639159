import { forwardRef } from 'react'
import { Flex, Input } from '../../common'
import { InputProps } from './Input/Input.types'
import { HelperText } from './TextField.styles'

const TextField = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      borderType = 'LINE',
      status = 'DEFAULT',
      align = 'left',
      size = 'M',
      helpText,
      ...inputProps
    },
    ref,
  ) => {
    return (
      <Flex gap={8} flexDirection="column" width={'100%'}>
        <Input
          ref={ref}
          borderType={borderType}
          status={status}
          align={align}
          size={size}
          {...inputProps}
        />
        {helpText && <HelperText status={status}>{helpText}</HelperText>}
      </Flex>
    )
  },
)

export default TextField

TextField.displayName = 'TextField'
