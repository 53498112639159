import { RefObject } from 'react'

function findElement<T extends HTMLElement>(target: React.RefObject<T> | HTMLElement | string) {
  if (typeof target !== 'object') {
    return document.querySelector<T>(target)
  }

  if ('nodeType' in target && target.nodeType === 1) {
    return target as T
  }

  if (Object.prototype.hasOwnProperty.call(target as RefObject<T>, 'current')) {
    return (target as React.RefObject<T>).current
  }

  return null
}

export default findElement
