import { CSSObject } from 'styled-components'
import { ButtonStyleProps } from '../Button.types'

export const setDefaultStyle = (props: ButtonStyleProps): CSSObject => {
	return {
		fontWeight: props.theme.fontWeight.medium,
		cursor: 'pointer',
		border: '1px solid transparent',
		display: 'inline-flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: '4px',
	}
}

export const setVariantStyle = (props: ButtonStyleProps): CSSObject | null => {
	switch (props.variant) {
		case 'blue':
			return {
				backgroundColor: props.theme.color.primary['300'],
				color: props.theme.color.gray['0'],
				'@media (pointer: fine)': {
					':hover': {
						backgroundColor: props.theme.color.primary['400'],
					},
				},
				'@media (pointer: coarse)': {
					':active': {
						backgroundColor: props.theme.color.primary['400'],
					},
				},
			}
		case 'black':
			return {
				backgroundColor: props.theme.color.gray['700'],
				color: props.theme.color.gray['0'],
				'@media (pointer: fine)': {
					':hover': {
						backgroundColor: props.theme.color.gray['600'],
					},
				},
				'@media (pointer: coarse)': {
					':active': {
						backgroundColor: props.theme.color.gray['600'],
					},
				},
			}
		case 'red':
			return {
				backgroundColor: props.theme.color.red['300'],
				color: props.theme.color.gray['0'],
				'@media (pointer: fine)': {
					':hover': {
						backgroundColor: props.theme.color.red['400'],
					},
				},
				'@media (pointer: coarse)': {
					':active': {
						backgroundColor: props.theme.color.red['400'],
					},
				},
			}
		case 'white':
			return {
				backgroundColor: props.theme.color.gray['0'],
				color: props.theme.color.gray['700'],
				'@media (pointer: fine)': {
					':hover': {
						border: `1px solid ${props.theme.color.gray['300']}`,
					},
				},
				'@media (pointer: coarse)': {
					':active': {
						border: `1px solid ${props.theme.color.gray['300']}`,
					},
				},
			}

		default:
			return null
	}
}

export const setStretchStyle = (props: ButtonStyleProps): CSSObject | null => {
	return props.stretch ? { width: '100%' } : null
}

export const setDisableStyle = (props: ButtonStyleProps): CSSObject | null => {
	if (!props.disabled) {
		return null
	}

	switch (props.variant) {
		case 'white':
			return {
				backgroundColor: props.theme.color.gray['0'],
				color: props.theme.color.gray['50'],
				border: `1px solid ${props.theme.color.gray['50']}`,
				':hover': {
					backgroundColor: props.theme.color.gray['0'],
				},
			}
		default:
			return props.border
				? {
						backgroundColor: props.theme.color.gray['0'],
						color: props.theme.color.gray['50'],
						border: `1px solid ${props.theme.color.gray['50']}`,
						':hover': {
							backgroundColor: props.theme.color.gray['0'],
						},
				  }
				: {
						backgroundColor: props.theme.color.gray['50'],
						color: props.theme.color.gray['0'],
						':hover': {
							backgroundColor: props.theme.color.gray['50'],
						},
				  }
	}
}

export const setRoundStyle = (props: ButtonStyleProps): CSSObject | null => {
	return props.round ? { borderRadius: `100px` } : null
}

export const setSizeStyle = ({ theme, size }: ButtonStyleProps): CSSObject | null => {
	switch (size) {
		case 'large':
			return {
				fontSize: theme.fontSize.l,
				lineHeight: theme.lineHeight.l,
				padding: '0 20px',
				borderRadius: '8px',
				height: '56px',
			}
		case 'medium':
			return {
				fontSize: theme.fontSize.m,
				lineHeight: theme.lineHeight.m,
				padding: '0 20px',
				borderRadius: '8px',
				height: '44px',
			}
		case 'small':
			return {
				fontSize: theme.fontSize.s,
				lineHeight: theme.lineHeight.s,
				padding: '0 16px',
				borderRadius: '4px',
				height: '28px',
			}
		case 'xsmall':
			return {
				fontSize: theme.fontSize.xs,
				lineHeight: theme.lineHeight.xs,
				padding: '0 12px',
				borderRadius: '4px',
				height: '24px',
			}
		default:
			return null
	}
}

export const setBorderStyle = ({ theme, border, variant }: ButtonStyleProps): CSSObject | null => {
	if (!border) return null

	switch (variant) {
		case 'blue':
			return {
				border: `1px solid ${theme.color.primary['300']}`,
				backgroundColor: theme.color.gray['0'],
				color: theme.color.primary['300'],
				'@media (pointer: fine)': {
					':hover:not(:disabled)': {
						border: `1px solid ${theme.color.primary['400']}`,
						color: theme.color.primary['400'],
					},
				},
				'@media (pointer: coarse)': {
					':active': {
						border: `1px solid ${theme.color.primary['400']}`,
						color: theme.color.primary['400'],
					},
				},
			}
		case 'black':
			return {
				backgroundColor: theme.color.gray['700'],
				color: theme.color.gray['0'],
			}
		case 'red':
			return {
				border: `1px solid ${theme.color.red['300']}`,
				backgroundColor: theme.color.gray['0'],
				color: theme.color.red['300'],
				'@media (pointer: fine)': {
					':hover:not(:disabled)': {
						border: `1px solid ${theme.color.red['400']}`,
						color: theme.color.red['400'],
					},
				},
				'@media (pointer: coarse)': {
					':active': {
						border: `1px solid ${theme.color.red['400']}`,
						color: theme.color.red['400'],
					},
				},
			}
		case 'white':
			return {
				backgroundColor: theme.color.gray['0'],
				color: theme.color.gray['700'],
				border: `1px solid ${theme.color.gray['100']}`,
				'@media (pointer: fine)': {
					':hover:not(:disabled)': {
						border: `1px solid ${theme.color.gray['300']}`,
					},
				},
				'@media (pointer: coarse)': {
					':active': {
						border: `1px solid ${theme.color.gray['300']}`,
					},
				},
			}
		default:
			return null
	}
}
