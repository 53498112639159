import { Text } from 'components/common'
import styled, { css } from 'styled-components'

export const AuthRegisterContentWrapper = styled.div`
  width: 100%;
  padding: 88px 0;

  ${({ theme }) => css`
    & .color-gray-300 {
      color: ${theme.color.gray[300]};
    }
    ${theme.breakpoint.mediaQuery.down('md')} {
      padding: 0;
    }
  `}
`
export const AuthRegisterContentBox = styled.div`
  ${({ theme }) => css`
    background: ${theme.color.gray[0]};
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 32px;
    border-radius: 8px;
    box-shadow: 0 3px 10px 0px ${theme.color.shadow[1]};

    ${theme.breakpoint.mediaQuery.down('md')} {
      max-width: unset;
      padding: 20px;
      box-shadow: unset;
    }
  `}
`

export const AuthRegisterFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 48px;

  ${({ theme }) => css`
    ${theme.breakpoint.mediaQuery.down('md')} {
      gap: 32px;
      margin-bottom: 180px;
    }
  `}
`

export const AuthRegisterFormItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const AuthRegisterTermsText = styled(Text.S)`
  ${({ theme }) => css`
    color: ${theme.color.gray[300]};
    text-align: center;
  `}
`

export const AuthRegisterTermsTextBr = styled.br`
  @media screen and (max-width: 417px) {
    display: none;
  }
`

export const AuthRegisterFormButtonWrapper = styled.div`
  ${({ theme }) => css`
    ${theme.breakpoint.mediaQuery.down('md')} {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 20px 32px 20px;
      background: ${theme.color.gray[0]};
    }
  `}
`
