import { BusinessDaysOfWeek, RegularDayOfWeekType } from 'types/RegisterOwner'

/**
 * @description 영업일 정보를 영업시간에서 사용하는 요일 타입의 리스트를 변환하여 반환하는 함수
 */
export function transformBusinessDaysOfWeekListFromRegularDayOfWeek(
  obj: RegularDayOfWeekType,
): BusinessDaysOfWeek[] {
  const dayMapping: Record<keyof RegularDayOfWeekType, BusinessDaysOfWeek> = {
    monday: 'MONDAY',
    tuesday: 'TUESDAY',
    wednesday: 'WEDNESDAY',
    thursday: 'THURSDAY',
    friday: 'FRIDAY',
    saturday: 'SATURDAY',
    sunday: 'SUNDAY',
  }

  return (Object.keys(obj) as (keyof RegularDayOfWeekType)[])
    .filter((day) => obj[day])
    .map((day) => dayMapping[day])
}
