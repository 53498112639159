import styled, { css } from 'styled-components'

export const TextXXLCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xxl};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.xxl};
  `}
`

export const TextXLCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xl};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.xl};
  `}
`

export const TextLCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.l};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.l};
  `}
`

export const TextM24Css = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.m};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.l};
  `}
`

export const TextM20Css = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.m};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.m};
  `}
`

export const TextSCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.s};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.s};
  `}
`

export const TextXSCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.regular};
    line-height: ${theme.lineHeight.xs};
  `}
`

const XXL = styled.p`
  ${TextXXLCss}
`

const XL = styled.p`
  ${TextXLCss}
`

const L = styled.p`
  ${TextLCss}
`

const M24 = styled.p`
  ${TextM24Css}
`

const M20 = styled.p`
  ${TextM20Css}
`

const S = styled.p`
  ${TextSCss}
`

const XS = styled.p`
  ${TextXSCss}
`

export { XXL, XL, L, M24, M20, S, XS }
