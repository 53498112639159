import styled, { css } from 'styled-components'

export const TitleXXLCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xxl};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.xxl};
  `}
`

export const TitleXLCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xl};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.xl};
  `}
`

export const TitleLCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.l};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.l};
  `}
`

export const TitleM24Css = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.m};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.l};
  `}
`

export const TitleM20Css = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.m};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.m};
  `}
`

export const TitleSCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.s};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.s};
  `}
`

export const TitleXSCss = css`
  ${({ theme }) => css`
    font-size: ${theme.fontSize.xs};
    font-weight: ${theme.fontWeight.bold};
    line-height: ${theme.lineHeight.xs};
  `}
`

const XXL = styled.h1`
  ${TitleXXLCss}
`

const XL = styled.h2`
  ${TitleXLCss}
`

const L = styled.h3`
  ${TitleLCss}
`

const M24 = styled.h4`
  ${TitleM24Css}
`

const M20 = styled.h4`
  ${TitleM20Css}
`

const S = styled.h5`
  ${TitleSCss}
`

const XS = styled.h6`
  ${TitleXSCss}
`

export { XXL, XL, L, M24, M20, S, XS }
